import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { Modal, Alert, Icon, Button, Input } from 'antd';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import { WrapperInput } from '../../components/WrapperInput';
import BG_MAIN from '../../images/bg-login.png';
import { Logo } from '../../components/Logo';

export const Login = (props) => {
	const [state, setstate] = useState({ titleError: '', messageError: '', loading: false });

	const handleOnSubmitForm = (e) => {
		e.preventDefault();
		const target = e.target;
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (target.email.value.length) {
			setstate({ ...state, titleError: '' });
			if (re.test(String(target.email.value).toLowerCase())) {
				setstate({ ...state, titleError: '' });
				if (target.password.value.length) {
					const form = {
						email: target.email.value,
						password: target.password.value,
					};

					setstate({ ...state, form, titleError: '' });
					handleSendData(form);

					// setTimeout(() => {
					// 	login(form)
					// 		.then((client) => console.log(client))
					// 		.catch((error) => {
					// 			let message = '';
					// 			switch (error.code) {
					// 				case 'auth/invalid-email':
					// 					message = 'Esta dirección de correo electrónico no es valida.';
					// 					break;
					// 				case 'auth/user-disabled':
					// 					message = 'El correo electrónico dado ha sido deshabilitado.';
					// 					break;
					// 				case 'auth/user-not-found':
					// 					message = 'Esta dirección de correo electrónico no esta registrado.';
					// 					break;
					// 				case 'auth/wrong-password':
					// 					message =
					// 						'La contraseña no es válida para el correo electrónico proporcionado.';
					// 					break;
					// 				default:
					// 					break;
					// 			}
					// 			handleShowModal(message);
					// 			setstate({ ...state, loading: false });
					// 		});
					// }, 500);
				} else {
					setstate({
						...state,
						titleError: 'Contraseña:',
						messageError: 'Ingresa tu contraseña para continuar.',
					});
				}
			} else {
				setstate({ ...state, titleError: 'Este correo no es valido:', messageError: 'Ej: correo@empresa.com' });
			}
		} else {
			setstate({ ...state, titleError: 'Correo:', messageError: 'Ingresa tu correo para continuar' });
		}
	};

	const handleSendData = (form) => {
		// console.log(currentUser);
		props.currentUser(form);
	};

	const handleShowModal = (message) => {
		Modal.warning({ title: 'Error', content: message });
	};

	const showErrorMessage = (isExistError) => {
		return isExistError ? (
			<Alert
				description={state.messageError}
				style={{ margin: '1em 0' }}
				message={state.titleError}
				type="error"
				showIcon
				closable
			/>
		) : null;
	};

	return (
		<Grid fluid className="p-none">
			<Row middle="xs" style={{ height: '100vh' }}>
				<Col md={6}>
					<Row center="xs">
						<Col xs={10} style={{ maxWidth: 640 }}>
							<Row center="xs" className="wrapper--input">
								<Col xs={12} style={{ maxWidth: 148 }}>
									<Logo />
								</Col>
							</Row>
							<Row start="xs">
								<Col xs={12}>{showErrorMessage(state.titleError.length ? true : false)}</Col>
							</Row>
							<form onSubmit={handleOnSubmitForm}>
								<InputNormal
									md={12}
									label="Correo eléctronico"
									placeholder="Ingresa tu correo"
									size="large"
									name="email"
									type="text"
								/>
								<InputNormal
									md={12}
									label="Contraseña"
									placeholder="Ingresa tu contraseña"
									size="large"
									name="password"
									type="password"
								/>
								<Col md={12}>
									<Row end="md">
										<Col md={6}>
											<Link to="/resertear-acceso">¿Olvide mi contraseña?</Link>
										</Col>
									</Row>
								</Col>
								<Col xs={12} style={{ padding: '1em 0 0 0' }}>
									<Button type="primary" htmlType="submit" className="w-100" size="large">
										{state.loading ? <Icon type="loading" style={{ padding: '0 8px' }} /> : null}{' '}
										Iniciar sesión
									</Button>
								</Col>
							</form>
						</Col>
					</Row>
				</Col>
				<Col xs={6} className="hidden--mobile">
					<Row>
						<Col xs={12} className="img-cover">
							<img src={BG_MAIN} alt="bg-main" />
						</Col>
						<Col xs={12} style={{ position: 'absolute', padding: 32 }} className="h-100">
							<Row bottom="xs" className="h-100">
								<Col xs={12}>
									<h1 className="c-white">Gestiona en minutos</h1>
									<h3 className="c-white">
										Administra, monitorea y obtén mayor control desde la palma de tu mano.
									</h3>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Grid>
	);
};

export const InputNormal = (props) => {
	// value={props.value}
	return (
		<WrapperInput xs={props.xs} md={props.md} label={props.label} className={props.className}>
			<Col xs={12}>
				{props.type === 'TextArea' ? (
					<Input.TextArea
						disabled={props.disabled}
						size={props.size}
						rows={4}
						name={props.name}
						placeholder={props.placeholder}
						onChange={props.onChange}
					/>
				) : (
					<Input
						disabled={props.disabled}
						size={props.size}
						type={props.type ? props.type : 'text'}
						name={props.name}
						placeholder={props.placeholder}
						onChange={props.onChange}
					/>
				)}
			</Col>
		</WrapperInput>
	);
};
