import React from 'react';
import { Select, Radio, Input as InputNormal } from 'antd';
import { getClient } from '../config/Helpers';
import { Row, Col } from 'react-flexbox-grid';

const { Option } = Select;

export const FilterListConductor = (props) => {
	return (
		<Row middle="xs">
			{/* <Col md={12} lg={8} style={{ padding: '4px 0' }}>
				<Row>
					<Col md={6}>
						<Row style={{ padding: '4px 8px' }}>
							<Radio.Group
								onChange={(e) => props.onRadio(e, 'status')}
								defaultValue="active"
								buttonStyle="solid"
							>
								<Radio.Button value="active">Activos</Radio.Button>
								<Radio.Button value="inactive">Inactivos</Radio.Button>
							</Radio.Group>
						</Row>
					</Col>
					<Col md={6}>
						<Row start="lg" end="md" style={{ padding: '4px 8px' }}>
							<Radio.Group
								onChange={(e) => props.onRadio(e, 'statusRequest')}
								defaultValue="all"
								buttonStyle="solid"
							>
								<Radio.Button value="all">Todos</Radio.Button>
								<Radio.Button value="in_process">En camino</Radio.Button>
								<Radio.Button value="done">Completadas</Radio.Button>
							</Radio.Group>
						</Row>
					</Col>
				</Row>
			</Col> */}
			<Col md={12} style={{ padding: '4px 0' }}>
				<Row end="xs">
					<Col xs={12}>
						<InputNormal
							placeholder="Busca por tecnico"
							onChange={props.onInputFilter}
							name="filterByUsee"
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
