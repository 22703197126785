import styled from 'styled-components';

export const List = styled.ul`
	overflow-x: auto;
	overflow-y: hidden;
	list-style: none;
	white-space: nowrap;
	padding: 0;
`;
export const ListItem = styled.li`
	display: inline-block;
	padding: 8px 16px;
	margin-right: 16px;
`;
