import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { BarGrid } from '../../components/BarGrid';
import { DoughnutGrid } from '../../components/DoughnutGrid';
import { PieGrid } from '../../components/PieGrid';
import { HeaderDashboard } from '../../components/HeaderDashboard';
import { getAllData, getClient } from '../../config/Helpers';
import { SUPER_ROOT } from '../../constants/Constans';
import moment from 'moment';
import 'moment/locale/es';

export class Main extends Component {
	state = {
		services: [],
		counterTypeService: {
			revision: 0,
			instalacion: 0,
			desinstalacion: 0,
		},
		counterServiceWithMonth: {
			Enero: 0,
			Febrero: 0,
			Marzo: 0,
			Abril: 0,
			Mayo: 0,
			Junio: 0,
			Julio: 0,
			Agosto: 0,
			Septiembre: 0,
			Octubre: 0,
			Noviembre: 0,
			Diciembre: 0,
		},
		employeeAccountant: {
			ADMINISTRADOR: 0,
			TECNICO: 0,
		},
	};

	componentDidMount() {
		const isSuperAdmin = getClient()?.isSuperRoot;
		getAllData('service', (services) => {
			const result = services.filter(
				(item) => {
					return !isSuperAdmin
						? (getClient().technicians || []).indexOf(item.installation_technician) !== -1
						: item;
				},
				[services]
			);

			const counterTypeService = result.reduce(
				(acc, el) => ({
					...acc,
					[el.service_type]: (acc[el.service_type] || 0) + 1,
				}),
				{}
			);

			const counterServiceWithMonth = result.reduce((acc, el) => {
				let label;
				switch (moment(el.service_date).month()) {
					case 0:
						label = 'Enero';
						break;
					case 1:
						label = 'Febrero';
						break;
					case 2:
						label = 'Marzo';
						break;
					case 3:
						label = 'Abril';
						break;
					case 4:
						label = 'Mayo';
						break;
					case 5:
						label = 'Junio';
						break;
					case 6:
						label = 'Julio';
						break;
					case 7:
						label = 'Agosto';
						break;
					case 8:
						label = 'Septiembre';
						break;
					case 9:
						label = 'Octubre';
						break;
					case 10:
						label = 'Noviembre';
						break;
					case 11:
						label = 'Diciembre';
						break;
					default:
						label = 'DESCONOCIDO';
						break;
				}
				return { ...acc, [label]: (acc[label] || 0) + 1 };
			}, {});

			this.setState({
				services,
				counterTypeService: { ...this.state.counterTypeService, ...counterTypeService },
				counterServiceWithMonth: { ...this.state.counterServiceWithMonth, ...counterServiceWithMonth },
			});
		});

		getClient() &&
			getAllData('users', (data) => {
				const result = data
					.filter(
						(item) => {
							return !isSuperAdmin ? (getClient().technicians || []).indexOf(item._id) !== -1 : item;
						},
						[data]
					)
					.concat(!isSuperAdmin ? getClient() : {});

				const employeeAccountant = result.reduce(
					(acc, el) => ({
						...acc,
						[el.type]: (acc[el.type] || 0) + 1,
					}),
					{}
				);

				this.setState({
					employeeAccountant: { ...this.state.employeeAccountant, ...employeeAccountant },
				});
			});
	}

	render() {
		// console.log(this.state);
		return (
			<Grid fluid>
				<Row>
					<Col xs={12}>
						<HeaderDashboard title="Dashboard" />
						<Row>
							<Col md={6}>
								<DoughnutGrid data={this.state.counterTypeService} />
							</Col>
							<Col md={6}>
								<PieGrid data={this.state.employeeAccountant} />
							</Col>
							<Col md={12}>
								<BarGrid data={this.state.counterServiceWithMonth} />
							</Col>
						</Row>
					</Col>
				</Row>
			</Grid>
		);
	}
}
