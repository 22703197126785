import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { HeaderDashboard } from '../../components/HeaderDashboard';
import { AddClientToSystem } from '../../components/AddClientToSystem';
import { Table, Button, Popconfirm, Input, Icon } from 'antd';
// import { SUPER_ROOT } from '../../constants/Constans';
import { deleteClientService, getAllData, getClient } from '../../config/Helpers';
import Highlighter from 'react-highlight-words';

export class ClientsInTheSystem extends Component {
	state = {
		data: [],
		admins: [],
		itemCard: null,
		searchText: '',
		searchedColumn: '',
	};

	handleTableChange = (value) => {
		console.log(value);
	};

	handleShowUser = (id, type) => {
		console.log(id);
		console.log(type);
	};

	componentDidMount() {
		const isSuperAdmin = getClient()?.isSuperRoot;
		getClient() &&
			getAllData('clients', (data) => {
				const clients = data.filter(
					(item) => {
						return !isSuperAdmin ? (getClient().clients || []).indexOf(item._id) !== -1 : item;
					},
					[data]
				);

				this.setState({ data: clients });
			});

		getAllData('users', (data) => {
			const admins = data.filter((item) => item.type === 'ADMINISTRADOR');
			this.setState({ admins });
		});
	}

	handleActionButtonTable = (record, type) => {
		switch (type) {
			case 'delete':
				deleteClientService(record, () => {
					const data = this.state.data.filter((item) => item._id !== record._id);
					this.setState({ data });
					this.handleShowModal('Usuario eliminado de forma correcta');
				});
				break;
			case 'edit':
				this.setState({ itemCard: record, type: 'itemCard', visible: true });
				console.log('EDIT');
				break;

			default:
				this.props.history.push({ pathname: `/admin/clientes/${record._id}` });
				break;
		}
	};

	showModal = () => {
		this.setState({ visible: true, type: 'client' });
	};

	hiddenModal = () => {
		this.setState({ visible: false });
	};

	handleAddClient = (data, _id) => {
		const newData = this.state.data;
		newData.push({ ...data, _id });
		this.setState({ data: newData });
	};

	handleUpdateService = (data) => {
		const newData = this.state.data.filter((item) => item._id !== data._id);
		newData.push({ ...data });
		this.setState({ data: newData });
	};

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Buscador`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon="search"
					size="small"
					style={{ width: 90, marginRight: 8 }}
				>
					Buscar
				</Button>
				<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					Limpiar
				</Button>
			</div>
		),
		filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text.toString()}
				/>
			) : (
				text
			),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	render() {
		const columns = [
			{
				title: 'ID',
				dataIndex: '_id',
				width: 160,
			},
			{
				title: 'Cliente',
				dataIndex: 'name',
				...this.getColumnSearchProps('name'),
			},
			// {
			// 	title: 'Admin',
			// 	index: 'admin',
			// 	render: (e, record) => {
			// 		const data = this.state.admins.filter((item) => item._id === record.id_admin);
			// 		return data.length && data[0].name;
			// 	},
			// },
			{
				title: 'Sucursales',
				dataIndex: 'inputList',
				render: (value, data) => data.inputList.length,
				// width: 132,
			},
			{
				index: 'action',
				render: (value, record) => (
					<Row center="xs" middle="xs">
						<Col xs={12}>
							<Button
								type="primary"
								shape="round"
								icon="eye"
								size={'large'}
								style={{ marginRight: 8 }}
								onClick={() => this.handleActionButtonTable(record, 'view')}
							/>
							<Button
								type="primary"
								shape="round"
								icon="edit"
								size={'large'}
								style={{ marginRight: 8 }}
								onClick={() => this.handleActionButtonTable(record, 'edit')}
							/>
							<Popconfirm
								title="¿Estas seguro de eliminar este servicio?"
								onConfirm={() => this.handleActionButtonTable(record, 'delete')}
								onCancel={() => {}}
								okText="Si, Eliminar"
								cancelText="Cancelar"
							>
								<Button type="danger" shape="round" icon="delete" size={'large'} />
							</Popconfirm>
						</Col>
					</Row>
				),
			},
		];

		return (
			<Grid fluid>
				<AddClientToSystem
					admins={this.state.admins}
					visible={this.state.visible}
					itemCard={this.state.itemCard}
					type={this.state.type}
					onHiddenModal={this.hiddenModal}
					onAddClient={this.handleAddClient}
					onUpdateClient={this.handleUpdateService}
				/>
				<Row style={{ width: '100%', margin: 'auto' }}>
					<Col xs={12}>
						<HeaderDashboard
							title="Lista de Clientes"
							extra
							onClick={this.showModal}
							textBtn="AGREGAR USUARIO"
						/>
						<Row className="pt-1">
							<Col xs={12}>
								<Table
									bordered
									columns={columns}
									rowKey={(record) => record._id}
									scroll={{ x: 'calc(200px + 100%)', y: 800 }}
									dataSource={this.state.data}
									pagination={{ pageSize: 100 }}
									loading={this.state.loading}
									onChange={this.handleTableChange}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Grid>
		);
	}
}
