import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { InputNormal } from './InputNormal';
import { OptionNormal } from './OptionNormal';
import { WrapperInput } from './WrapperInput';
import { Alert, Button, Popconfirm } from 'antd';
import AutocompleteAdress from 'react-google-autocomplete';

export const FormAddClient = React.forwardRef((props, ref) => {
	if (Object.getOwnPropertyNames(props.data).length === 0) {
		return null;
	}

	let isUpdateUser = props.type === 'itemCard';
	return (
		<>
			<Row>
				{props.messageError.length ? (
					<Alert message={props.messageError} type="error" style={{ margin: '0 0 20px', width: '100%' }} />
				) : null}
				<Col xs={12}>
					<form onSubmit={(e) => e.preventDefault()} noValidate>
						<Row>
							<InputNormal
								md={6}
								label={`Nombre Completo`}
								name={`name`}
								value={props.data.name || ''}
								onChange={props.onChange}
							/>
							<OptionNormal
								md={6}
								type
								isAdmin
								data={props.admins}
								label={`¿Quien administra este cliente?`}
								placeholder="Busca por el nombre"
								defaultValue={props.data.id_admin || ''}
								onChange={(e) => props.onSelect(e, 'id_admin')}
							/>
							{props.data.inputList.map((x, i) => {
								return (
									<Col xs={12} key={i}>
										<Row>
											<InputNormal
												md={6}
												label={`Sucursal`}
												name={`branch_office`}
												value={x.branch_office || ''}
												onChange={(e) => props.onChange(e, i)}
											/>
											<WrapperInput md={6} label={'Dirección'}>
												<Col xs={12}>
													{window.google !== undefined && (
														<AutocompleteAdress
															type="text"
															placeholder="Busca la dirección"
															className="ant-input"
															ref={ref}
															className="i-size-large ant-input"
															defaultValue={x.address.maps.direction}
															onPlaceSelected={(e) => props.onPlaceSelected(e, i)}
															types={['address']}
															componentRestrictions={{ country: 'mx' }}
														/>
													)}
												</Col>
											</WrapperInput>
											<Col xs={12}>
												{props.data.inputList.length !== 1 && (
													<Popconfirm
														title="¿Estas seguro de eliminar esta dirección?"
														onConfirm={() => props.onRemoveInput(i)}
														onCancel={() => {}}
														okText="Si, Eliminar"
														cancelText="Cancelar"
													>
														<Button
															type="danger"
															shape="round"
															icon="delete"
															size={'large'}
														/>
													</Popconfirm>
												)}
												{props.data.inputList.length - 1 === i && (
													<Button
														type="primary"
														shape="round"
														icon="plus"
														size={'large'}
														style={{ marginRight: 8 }}
														onClick={props.onAddInput}
													/>
												)}
											</Col>
										</Row>
									</Col>
								);
							})}
						</Row>
					</form>
				</Col>
			</Row>
		</>
	);
});
