import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import {
	API_GOOGLE_KEY,
	STATUS_PENDING_WORKER,
	WORKER_ACCEPTED,
	WORKER_ARRIVED,
	WORKER_IN_PROGRESS,
	WORKER_REJECTED,
	WORKER_DONE,
} from '../../constants/Constans';
import { HeaderDashboard } from '../../components/HeaderDashboard';
import { InfoWindow, withGoogleMap, withScriptjs, GoogleMap, Marker } from 'react-google-maps';
import { getCurrentServiceMap, getCurrentService } from '../../config/Helpers';

const style = {
	boxMap: {
		background: 'white',
		padding: '2em',
		borderRadius: '2em',
		boxShadow: '22px 32px 228px -25px rgba(1,128,250,0.26)',
		WebkitBoxShadow: '22px 32px 228px -25px rgba(1,128,250,0.26)',
		MozBoxShadow: '22px 32px 228px -25px rgba(1,128,250,0.26)',
	},
};

const Map = withScriptjs(
	withGoogleMap((props) => {
		let iconMarker = new window.google.maps.MarkerImage(
			Image,
			null /* size is determined at runtime */,
			null /* origin is 0,0 */,
			null /* anchor is bottom center of the scaled image */,
			new window.google.maps.Size(100, 100)
		);

		let markerOffline = new window.google.maps.MarkerImage(
			null,
			null /* size is determined at runtime */,
			null /* origin is 0,0 */,
			null /* anchor is bottom center of the scaled image */,
			new window.google.maps.Size(100, 100)
		);

		const bounds = new window.google.maps.LatLngBounds();

		props.data.map((item) => {
			if (item) {
				bounds.extend(new window.google.maps.LatLng(item.latitude, item.longitude));
			}
		});

		return (
			<GoogleMap
				defaultZoom={12}
				ref={(map) => {
					if (map && props.data.length > 0 && props.isFirstLoad) {
						props.doneShowingMap();
						map.fitBounds(bounds);
					}

					props.onMapReady(map);
				}}
				center={props.center}
				onClick={props.onMapClick}
			>
				{props.data.map((item) => {
					return item ? (
						<Marker
							position={{
								lat: item.latitude,
								lng: item.longitude,
							}}
							icon={iconMarker}
							defaultAnimation={window.google.maps.Animation.DROP}
							onClick={() => {}}
						/>
					) : null;
				})}
				{/* {props.isMarkerShown && <Marker position={props.markerPosition} icon={iconMarker} />} */}
			</GoogleMap>
		);
	})
);

export class CurrentService extends Component {
	state = {
		data: null,
		admins: [],
		locations: [],
		itemCard: null,
		isFirstLoad: true,
		center: { lat: 19.429592, lng: -99.199975 },
		map: null,
	};

	componentDidMount() {
		const { id } = this.props.match.params;
		getCurrentServiceMap(id, (data) => {
			const locations = data.map((c) => c.location);
			this.setState({ locations });
		});

		getCurrentService(id, (data) => {
			this.setState({ data });
		});
	}

	render() {
		const { data } = this.state;

		if (data === null) {
			return 'Cargando...';
		}

		let currentStatusService;
		switch (data.status_task) {
			case STATUS_PENDING_WORKER:
				currentStatusService = 'PENDIENTE POR REALIZAR';
				break;
			case WORKER_ACCEPTED:
				currentStatusService = 'SERVICIO ACEPTADO';
				break;
			case WORKER_ARRIVED:
				currentStatusService = 'EN CAMINO HACIA EL SERVICIO';
				break;
			case WORKER_IN_PROGRESS:
				currentStatusService = 'EN PROGRESO';
				break;
			case WORKER_REJECTED:
				currentStatusService = 'RECHAZADO';
				break;

			default:
				// WORKER_DONE
				currentStatusService = 'COMPLETADO';
				break;
		}

		return (
			<Grid fluid>
				<Row style={{ width: '100%', margin: 'auto' }}>
					<Col xs={12}>
						<HeaderDashboard title={`Detalles del servicio ${data.order}`} />
						<Row style={{ padding: '2em 2em 0' }}>
							<Col xs={6}>
								<Row>
									<Col xs={12}>
										<h4>{`ORDEN: ${data.order}`}</h4>
									</Col>
									<Col xs={12}>
										<h4>{`CLIENTE: ${data.client.name}`}</h4>
									</Col>
									<Col xs={12}>
										<h4>{`TECNICO: ${data.installation_technician_meta_data.name}`}</h4>
									</Col>
								</Row>
							</Col>
							<Col xs={6}>
								<Row>
									<Col xs={12}>
										<h4>{`PRIORIDAD: ${data.priority}`}</h4>
									</Col>
									<Col xs={12}>
										<h4>{`TIPO DE SERVICIO: ${data.service_type.toUpperCase()}`}</h4>
									</Col>
									<Col xs={12}>
										<h4>{`ESTATUS DE SERVICIO: ${currentStatusService}`}</h4>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row style={{ padding: '2em' }}>
							<Col xs={12} style={style.boxMap}>
								<div className="map">
									<Map
										googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_GOOGLE_KEY}&libraries=places`}
										loadingElement={<div style={{ height: `auto`, width: 'auto' }} />}
										containerElement={<div style={{ height: `480px`, width: '100%' }} />}
										mapElement={<div style={{ height: `480px`, width: '100%' }} />}
										doneShowingMap={() => this.setState({ isFirstLoad: false })}
										defaultZoom={32}
										center={this.state.center}
										data={this.state.locations}
										isFirstLoad={this.state.isFirstLoad}
										onMapReady={(ref) =>
											ref && this.state.map == null && this.setState({ map: ref })
										}
										isMarkerShown={true}
										onMapClick={(e) => {
											//this.setState({ markerPosition: e.latLng, hasAlreadyPickedLocation: true, center: e.latLng });
											//this.updateDirections(e.latLng.lat(), e.latLng.lng())
										}}
									/>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Grid>
		);
	}
}
