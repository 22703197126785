import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Main } from './Main';
import { Sidebar } from '../../components/Sidebar';
import { ClientsInTheSystem } from './ClientsInTheSystem';
import { CurrentClientInTheSystem } from './CurrentClientInTheSystem';
import { CalendarWorks } from './CalendarWorks';
import { UsersInTheSystem } from './UsersInTheSystem';
import { MapView } from './MapView';
import { CurrentClientsInTheSystem } from './CurrentClientsInTheSystem';
import { ServicesWorks } from './ServicesWorks';
import { CurrentService } from './CurrentService';

export const Dashboard = (props) => {
	const { isAdmin } = props;
	return (
		<Router>
			<div style={{ display: 'flex' }} className="d-block--mobile">
				<Sidebar isAdmin={isAdmin} />
				<Switch>
					{isAdmin && <Route exact path="/admin" component={Main} />}
					<Route
						exact
						path="/admin/calendario"
						render={(props) => <CalendarWorks {...props} isAdmin={isAdmin} />}
					/>
					<Route path="/admin/servicio/:id" component={CurrentService} />
					<Route exact path="/admin/servicios/nuevo" component={CurrentClientInTheSystem} />
					<Route exact path="/admin/servicios/editar/:id" component={CurrentClientInTheSystem} />
					<Route path="/admin/servicios" render={(props) => <ServicesWorks {...props} isAdmin={isAdmin} />} />

					{isAdmin && <Route exact path="/admin/mapa" component={MapView} />}
					{isAdmin && <Route exact path="/admin/tecnicos" component={UsersInTheSystem} />}
					{isAdmin && <Route exact path="/admin/administradores" component={UsersInTheSystem} />}
					{isAdmin && <Route exact path="/admin/clientes/:id" component={CurrentClientsInTheSystem} />}
					{isAdmin && <Route exact path="/admin/clientes" component={ClientsInTheSystem} />}
				</Switch>
			</div>
		</Router>
	);
};
