import React from 'react';
import { Button } from 'antd';
import { Row, Col } from 'react-flexbox-grid';

export const TitleModalPdf = (props) => {
	return (
		<Row middle="xs" style={{ padding: '0 2em 0 1em' }}>
			<Col xs={6}>
				<Row start="xs" middle="xs">
					<p style={{ margin: 0 }}>PDF</p>
				</Row>
			</Col>
			<Col xs={6}>
				<Row end="xs" middle="xs">
					<Button onClick={props.onClick} type="danger" ghost>
						Imprimir
					</Button>
				</Row>
			</Col>
		</Row>
	);
};
