import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { HeaderDashboard } from '../../components/HeaderDashboard';
import { InputNormal } from '../../components/InputNormal';
import { OptionNormal } from '../../components/OptionNormal';
import { WrapperInput } from '../../components/WrapperInput';
import { ConfirmCancelModal } from '../../components/ConfirmCancelModal';
import { ComponentOkAction } from '../../components/ComponentOkAction';
import { getClient, setClient } from '../../config/Helpers';
import { SUPER_ROOT } from '../../constants/Constans';
import { compareObj, registerUser, deleteUser, getAllData, updateData, resetPassword } from '../../config/Helpers';
import { Table, Modal, notification, Alert, Button, Popconfirm, TimePicker, Select, Input } from 'antd';
import moment from 'moment';
import 'moment/locale/es';

import { BASE_URL } from '../../constants/Constans';
import axios from 'axios';

export class UsersInTheSystem extends Component {
	state = {
		data: [],
		users: [],
		dataFilter: [],
		clients: [],
		loading: false,
		itemCard: {},
		_itemCard: {},
		visible: false,
		type: '',
		messageError: '',
		filterInput: '',
		user: {
			_id: '',
			name: '',
			email: '',
			password: '',
			type: '',
			service_hour_start: '',
			service_hour_end: '',
			type_employee: '',
			technicians: null,
			clients: null,
		},
		_user: {
			_id: '',
			name: '',
			email: '',
			password: '',
			type: '',
			service_hour_start: '',
			service_hour_end: '',
			type_employee: '',
			technicians: null,
			clients: null,
		},
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.visible !== this.state.visible) {
			// if (this.state.visible === false && (Object.entries(this.state.itemCard).length === 0) === false) {
			// 	this.setState({ itemCard: {}, _itemCard: {} });
			// }
			if (this.state.visible === false && (Object.entries(this.state.user).length === 0) === false) {
				this.setState({ user: this.state._user });
			}
		}

		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.getAllUsersSystem();
		}

		if (prevState.filterInput !== this.state.filterInput) {
			this.getUserFiltered();
		}
	}

	getUserFiltered = () => {
		const dataFilter = this.state.data.filter((item) => {
			if (item !== undefined) {
				const name = item.name.toUpperCase().indexOf(this.state.filterInput.toUpperCase());
				const existsInString = !!~name;
				return existsInString;
			}
		});

		this.setState({ dataFilter });
	};

	showModal = () => {
		this.setState({ visible: true, type: 'user' });
	};

	hiddenModal = () => {
		this.setState({ visible: false });
	};

	handleAddUser = (data) => {
		this.registerUserInBackEnd(data, (_id) => {
			delete data.password;
			registerUser(
				_id,
				{ ...data, _id },
				data.type,
				() => {
					const newData = this.state.data;
					newData.push({ ...data, _id });
					setTimeout(() => {
						this.setState({
							data: newData,
							upload: false,
							visible: false,
							service: this.state._service,
						});
						// id === getClient()._id && setClient({ ...getClient(), ...data,_id });
						let message =
							this.state.type === 'itemCard'
								? 'usuario actualizado correctamente'
								: 'El usuario se agrego con exito';
						this.handleShowNotification('success', message);
					}, 200);
				},
				(error) => {
					notification['error']({
						message: 'Hubo un error',
						description: `Este cambio no se ha aplicado en el sistema`,
					});
					// console.log(error);
					// console.log(`There is an error ${error.message}`);

					this.setState({
						upload: false,
						visible: false,
						service: this.state._service,
					});
				}
			);
		});
	};

	getAllUsersSystem = () => {
		const isTechnician = this.props.location.pathname === '/admin/tecnicos';
		const currentType = isTechnician ? 'TECNICO' : 'ADMINISTRADOR';
		const isSuperAdmin = getClient()?.isSuperRoot;
		getClient() &&
			getAllData('users', (users) => {
				const filterData = users.filter((item) => {
					if (isSuperAdmin) {
						return item && item.type === currentType;
					}

					if (isTechnician) {
						return item.type === currentType;
					}

					if (!isTechnician) {
						// && item.type !== 'TECNICO'
						return item._id === getClient()._id;
					}
				});

				const technicianAvailables = users.filter(
					(item) => {
						return !isSuperAdmin
							? (getClient().technicians || []).indexOf(item._id) !== -1
							: item && item.type === currentType;
					},
					[users]
				);

				const data = isTechnician ? technicianAvailables : filterData;

				this.setState({ data, users, dataFilter: data });
			});
	};

	componentDidMount() {
		this.getAllUsersSystem();

		getAllData('clients', (clients) => this.setState({ clients }));
	}

	registerUserInBackEnd = (data, cb) => {
		// console.log(data);
		axios
			.post(`${BASE_URL}/users`, data)
			.then((res) => {
				cb(res.data.uid);
			})
			.catch((error) => {
				this.setState({
					upload: false,
					// visible: false,
					// service: this.state._service,
				});
				this.showErrorMessageModal('No se logro registrar este usuario');
				// console.log(error);
			});
	};

	removeUserInBackEnd = (id_user, cb) => {
		axios
			.delete(`${BASE_URL}/users/${id_user}`)
			.then(() => cb())
			.catch((error) => {
				this.showErrorMessageModal('No se logro eliminar este usuario');
				console.log(error);
			});
	};

	showErrorMessageModal = (content) => {
		return Modal.error({
			title: 'Hubo un error',
			content,
		});
	};

	handleOk = (e) => {
		const re =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (this.state[this.state.type].name.length) {
			if (this.state[this.state.type].type.length) {
				let isUpdateUser = this.state.type === 'itemCard';
				let isValidateForm = false;
				if (isUpdateUser) {
					isValidateForm = true;
				} else {
					if (this.state[this.state.type].email.length) {
						if (re.test(String(this.state[this.state.type].email).toLowerCase())) {
							if (this.state[this.state.type].password.length) {
								isValidateForm = true;
							} else this.setState({ messageError: 'Ingresa una contraseña de acceso' });
						} else this.setState({ messageError: 'Este correo no es valido: Ej: correo@empresa.com' });
					} else this.setState({ messageError: 'Ingresa un correo para continuar' });
				}

				if (isValidateForm) {
					this.setState({ upload: true });

					setTimeout(() => {
						isUpdateUser
							? this.updateItemUser(this.state.itemCard._id, this.state.itemCard)
							: this.handleAddUser({ ...this.state.user });
					}, 100);
					// console.log('Guardar...');
				} else console.log('Falta datos por completar');
			} else this.setState({ messageError: 'Selecciona el perfil del usuario' });
		} else this.setState({ messageError: 'Ingresa el nombre del usuario' });
	};

	handleShowNotification = (type, title) => {
		notification[type]({
			message: title,
			description: `Este cambio ya se ha aplicado en el sistema`,
		});
	};

	handleInputChange = (event) => {
		const { name, value } = event.target;

		if (name === 'discount' || name === 'price' || name === 'stock') {
			if (value.length <= 10) {
				if (!isNaN(value)) {
					this.setState({ [this.state.type]: { ...this.state[this.state.type], [name]: parseInt(value) } });
				}
			}
			if (!value.length) {
				this.setState({ [this.state.type]: { ...this.state[this.state.type], [name]: '' } });
			}
		} else {
			this.setState({ [this.state.type]: { ...this.state[this.state.type], [name]: value } });
		}
	};

	handleSelect = (value, type) => {
		this.setState({ [this.state.type]: { ...this.state[this.state.type], [type]: value } });
	};

	handleTableChange = (value) => {
		console.log(value);
	};

	handleShowModal = (message) => {
		Modal.success({ title: 'Error', content: message });
	};

	updateItemUser = (id, data) => {
		// const _data = {
		// 	name: data.name,
		// 	type: data.type,
		// };

		const newData = this.state.data.filter((item) => item._id !== id);
		newData.push(data);

		updateData('users', id, data, () => {
			this.setState({
				data: newData,
				upload: false,
				visible: false,
				service: this.state._service,
			});

			console.log(data);
			id === getClient()._id && setClient({ ...getClient(), ...data });
			let message =
				this.state.type === 'itemCard' ? 'usuario actualizado correctamente' : 'El usuario se agrego con exito';
			this.handleShowNotification('success', message);

			console.log('USUARIO ACTUALIZADO');
		});
	};

	handleShowUser = (id, type) => {
		if (type === 'delete') {
			this.removeUserInBackEnd(id, () => {
				deleteUser(id, () => {
					const data = this.state.data.filter((item) => item._id !== id);
					this.setState({ data });
					this.handleShowModal('Usuario eliminado de forma correcta');
				});
			});
		} else {
			this.getItemCard(id);
		}
	};

	getItemCard = (id) => {
		const data = { ...this.state.data.filter((item) => item._id === id)[0] };
		this.setState({ itemCard: data, _itemCard: data, type: 'itemCard', visible: true });
	};

	handleRequestResetPassword = (id) => {
		const data = this.state.data.filter((item) => item._id === id);

		// console.log('RESET REQUEST ');

		if (data.length > 0) {
			resetPassword(data[0].email.toLowerCase())
				.then(() => {
					Modal.success({
						title: 'Revisa el correo para continuar',
						content: `Se han enviado las instrucciones para restablecer la contraseña al correo: ${data[0].email.toLowerCase()}`,
					});
				})
				.catch((error) => {
					Modal.error({
						title: 'Hubo un error',
						content: `Verifica que los datos esten correctos.`,
					});
					console.log(error);
				});
		}
	};

	handleOnChangeTime = (time, service_hour, type) => {
		this.setState({ [this.state.type]: { ...this.state[this.state.type], [type]: service_hour } });
	};

	showItemCardModal() {
		let dataOne = compareObj(this.state.itemCard, this.state._itemCard);
		let dataTwo = JSON.stringify(this.state.user) === JSON.stringify(this.state._user);
		return (
			<Modal
				title="Información de servicio"
				visible={this.state.visible}
				maskClosable={false}
				onOk={this.handleOk}
				okText={<ComponentOkAction upload={this.state.upload} />}
				okButtonProps={{
					disabled: dataOne === false || dataTwo === false ? false : true,
				}}
				closable={false}
				onCancel={dataOne === false || dataTwo === false ? null : this.hiddenModal}
				cancelText={
					dataOne === false || dataTwo === false ? (
						<ConfirmCancelModal onConfirm={this.hiddenModal} onCancel={() => {}} />
					) : null
				}
			>
				<FormAddService
					data={this.state[this.state.type]}
					type={this.state.type}
					state={this.state}
					messageError={this.state.messageError}
					onChange={this.handleInputChange}
					onSelect={this.handleSelect}
					onTime={this.handleOnChangeTime}
					onReset={this.handleRequestResetPassword}
				/>
			</Modal>
		);
	}

	render() {
		const columns = [
			{
				title: 'ID',
				dataIndex: '_id',
				fixed: 'left',
				width: 220,
			},
			{
				title: 'Nombre',
				dataIndex: 'name',
			},
			{
				title: 'Tipo de usuario',
				dataIndex: 'type',
				filters: [
					{ text: 'Administrador', value: 'ADMINISTRADOR' },
					{ text: 'Técnico', value: 'TECNICO' },
				],
				onFilter: (value, record) => record.type.includes(value),
			},
			{
				title: 'Correo',
				dataIndex: 'email',
			},
			{
				index: 'ACTION',
				render: (value, record) => (
					<Row center="xs" middle="xs">
						<Col xs={12}>
							<Button
								type="primary"
								shape="round"
								icon="edit"
								size={'large'}
								style={{ marginRight: 8 }}
								onClick={() => this.handleShowUser(record._id, 'edit')}
							/>
							<Popconfirm
								title="¿Estas seguro de eliminar este servicio?"
								onConfirm={() => this.handleShowUser(record._id, 'delete')}
								onCancel={() => {}}
								okText="Si, Eliminar"
								cancelText="Cancelar"
							>
								<Button
									type="danger"
									shape="round"
									icon="delete"
									size={'large'}
									style={{ marginLeft: 8 }}
								/>
							</Popconfirm>
						</Col>
					</Row>
				),
			},
		];

		return (
			<Grid fluid>
				<Row style={{ width: '100%', margin: 'auto' }}>
					<Col xs={12}>
						<HeaderDashboard
							extra
							title="Lista de Usuarios"
							onClick={this.showModal}
							textBtn="NUEVO USUARIO"
						/>
						<Row style={{ padding: '16px 0' }}>
							<Col xs={12}>
								<Input.Search
									placeholder="Filtra nombre de usuario"
									enterButton="Buscar"
									size="large"
									onSearch={(filterInput) => this.setState({ filterInput })}
								/>
							</Col>
						</Row>
						<Row className="pt-1">
							<Col xs={12}>
								<Table
									bordered
									columns={columns}
									rowKey={(record) => record._id}
									scroll={{ x: 'calc(200px + 100%)', y: 800 }}
									pagination={{ pageSize: 10 }}
									loading={this.state.loading}
									onChange={this.handleTableChange}
									dataSource={this.state.dataFilter}
								/>
							</Col>
						</Row>
						{this.showItemCardModal()}
					</Col>
				</Row>
			</Grid>
		);
	}
}

const FormAddService = (props) => {
	let isUpdateUser = props.type === 'itemCard';
	return (
		<>
			<Row>
				{props.messageError.length ? (
					<Alert message={props.messageError} type="error" style={{ margin: '0 0 20px', width: '100%' }} />
				) : null}
				<Col xs={12}>
					<form onSubmit={(e) => e.preventDefault()} noValidate>
						<Row>
							<InputNormal
								md={12}
								label={`Nombre Completo`}
								name={`name`}
								value={props.data.name || ''}
								onChange={props.onChange}
							/>
							<OptionNormal
								md={props.data.type === 'TECNICO' ? 6 : 12}
								data={['ADMINISTRADOR', 'TECNICO']}
								label={`Perfil de usuario`}
								defaultValue={props.data.type || ''}
								onChange={(e) => props.onSelect(e, 'type')}
							/>
							{props.data.type === 'TECNICO' && (
								<OptionNormal
									md={6}
									data={['EMPLEADO', 'PRESTADOR DE SERVICIOS']}
									label={`Tipo de usuario`}
									defaultValue={props.data.type_employee || ''}
									onChange={(e) => props.onSelect(e, 'type_employee')}
								/>
							)}
							{props.data.type_employee === 'EMPLEADO' && (
								<>
									<Col xs={12}>
										<h3>Horario de trabajo</h3>
									</Col>
									<WrapperInput md={6} label={'Inicia Jornada'}>
										<Col xs={12}>
											<TimePicker
												onChange={(a, b) => props.onTime(a, b, 'service_hour_start')}
												defaultValue={
													props.data.service_hour_start !== ''
														? moment(props.data.service_hour_start, 'HH:mm:ss')
														: ''
												}
												className="w-100"
											/>
										</Col>
									</WrapperInput>
									<WrapperInput md={6} label={'Concluye Jornada'}>
										<Col xs={12}>
											<TimePicker
												onChange={(a, b) => props.onTime(a, b, 'service_hour_end')}
												defaultValue={
													props.data.service_hour_end !== ''
														? moment(props.data.service_hour_end, 'HH:mm:ss')
														: ''
												}
												className="w-100"
											/>
										</Col>
									</WrapperInput>
								</>
							)}

							{props.data.type === 'ADMINISTRADOR' && (
								<>
									<WrapperInput label="Técnicos" md={6}>
										<Col xs={12}>
											<Select
												mode="multiple"
												style={{ width: '100%' }}
												placeholder="Técnicos asignados"
												value={props.data.technicians || []}
												onChange={(e) => props.onSelect(e, 'technicians')}
												optionLabelProp="label"
											>
												{props.state.users
													.filter((item) => item.type !== 'ADMINISTRADOR')
													.map((item, key) => {
														return (
															<Select.Option value={item._id} label={item.name} key={key}>
																{`${item.name} - ${item.type_employee}`}
															</Select.Option>
														);
													})}
											</Select>
										</Col>
									</WrapperInput>
									<WrapperInput label="Clientes" md={6}>
										<Col xs={12}>
											<Select
												mode="multiple"
												style={{ width: '100%' }}
												placeholder="Clientes asignados"
												value={props.data.clients || []}
												onChange={(e) => props.onSelect(e, 'clients')}
												optionLabelProp="label"
											>
												{props.state.clients.map((item, key) => {
													return (
														<Select.Option value={item._id} label={item.name} key={key}>
															{item.name}
														</Select.Option>
													);
												})}
											</Select>
										</Col>
									</WrapperInput>
								</>
							)}
							<InputNormal
								md={12}
								label={`Correo`}
								name={`email`}
								value={props.data.email || ''}
								disabled={isUpdateUser}
								onChange={props.onChange}
							/>

							<InputNormal
								md={12}
								label={`Contraseña`}
								name={`password`}
								type="password"
								value={props.data.password || ''}
								disabled={isUpdateUser}
								onChange={props.onChange}
							/>
						</Row>
					</form>
				</Col>
			</Row>
		</>
	);
};
