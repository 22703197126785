import { firebaseAuth, firebaseDatabase, firebaseStorage, firebaseFirestore } from './Firebase';
import { CLIENT_OBJECT_ID } from '../constants/Constans';

export const getClient = () => {
	let item = localStorage.getItem(CLIENT_OBJECT_ID);
	if (typeof item === 'string' && item !== 'undefined') {
		try {
			let client = JSON.parse(item);
			return client;
		} catch (e) {
			return null;
		}
	}

	return null;
};

export const setClient = (data) => {
	localStorage.setItem(CLIENT_OBJECT_ID, JSON.stringify(data));
};

export const saveUser = (uid, userInfo, typeOfClient, cb) => {
	// let userToUpload = {
	// 	// _id: userId,
	// 	// name: 'Juan Pérez',
	// 	// email: 'admin@blacsolutions.com',
	// 	// isVerified: true,
	// 	// type: 'ADMINISTRADOR',
	// 	name: userInfo.name,
	// 	email: userInfo.email,
	// 	isVerified: true,
	// 	type: typeOfClient,
	// };

	firebaseFirestore
		.collection('users')
		.doc(uid)
		.set({ ...userInfo })
		.then((docRef) => {
			cb();
			// firebaseFirestore.collection('users').doc(docRef.id).update({
			// 	_id: docRef.id,
			// });
			// cb(docRef.id);
		})
		.catch((error) => console.error('Error adding document: ', error));
};

export const saveClient = (data, cb) => {
	firebaseFirestore
		.collection('clients')
		.add({ ...data })
		.then((docRef) => {
			firebaseFirestore.collection('clients').doc(docRef.id).update({
				_id: docRef.id,
			});

			addAdminToClient({ ...data, _id: docRef.id });
			cb(docRef.id);
		})
		.catch((error) => console.error('Error adding document: ', error));
};

export const updateClient = (uid, data, cb) => {
	const itemRef = firebaseFirestore.collection('clients').doc(uid);
	itemRef
		.get()
		.then((doc) => {
			if (doc.exists) {
				if (doc.data().id_admin !== data.id_admin) {
					console.log(doc.data());
					console.log(data);
					removeAdminToClient(doc.data(), () =>
						addAdminToClient(data, () => {
							itemRef
								.set(data)
								.then(() => {
									console.log('Ha cambiado de admin exitosamente');
									cb(false);
								})
								.catch((error) => console.error('Error adding document: ', error));
						})
					);
				} else {
					itemRef
						.set(data)
						.then(() => cb(false))
						.catch((error) => console.error('Error adding document: ', error));
				}
			} else {
				itemRef
					.set(data)
					.then(() => cb(false))
					.catch((error) => console.error('Error adding document: ', error));

				addAdminToClient(data);
			}
		})
		.catch((error) => console.log('Error getting document:', error));
};

export const updateData = (type, uid, data, cb) => {
	firebaseFirestore
		.collection(type)
		.doc(uid)
		.update(data)
		.then(() => cb())
		.catch((error) => console.error('Error update document: ', error));
};

export const registerUser = (uid, user, typeOfClient, cb = {}) => {
	saveUser(uid, user, typeOfClient, () => cb());
};

export const deleteUser = (userId, cb) => {
	firebaseFirestore
		.collection(`users`)
		.doc(userId)
		.delete()
		.then(() => cb())
		.catch((error) => console.error('Error delete document: ', error));
};

export const getAllFirtsCollections = async (type) => {
	try {
		const snapshot = await firebaseFirestore.collection(type).get();
		const data = snapshot.docs.map((doc) => ({ ...doc.data(), keyDB: doc.id }));
		return data;
	} catch (error) {
		console.log(error);
		return [];
	}
};

export const getAllData = (type, cb) => {
	firebaseFirestore
		.collection(type)
		.get()
		.then((querySnapshot) => {
			const newData = [];
			querySnapshot.forEach((doc) => newData.push({ ...doc.data(), keyDB: doc.id }));
			cb(newData);
		})
		.catch((error) => console.log(error.message));
};

export const deleteClientService = (client, cb) => {
	const itemRef = firebaseFirestore.collection(`clients`).doc(client._id);

	itemRef
		.delete()
		.then(() => cb())
		.catch((error) => console.log(error.message));

	removeAdminToClient(client);
};

const addAdminToClient = (data, cb) => {
	const itemRefTechnician = firebaseFirestore.collection(`users`).doc(data.id_admin);
	itemRefTechnician
		.get()
		.then((doc) => {
			if (doc.exists) {
				const clients = doc.data().clients !== undefined ? doc.data().clients : [];
				clients.push(data._id);
				itemRefTechnician
					.set({ clients }, { merge: true })
					.then(() => {
						console.log('COMPLETE ADD CLIENT');
						cb();
					})
					.catch((error) => console.log(error.message));
			} else console.log('No such document!');
		})
		.catch((error) => console.log('Error getting document:', error));
};

const removeAdminToClient = (data, cb) => {
	const itemRefTechnician = firebaseFirestore.collection(`users`).doc(data.id_admin);

	itemRefTechnician
		.get()
		.then((doc) => {
			if (doc.exists) {
				const clients = doc.data().clients.filter((item) => item !== data._id);

				itemRefTechnician
					.set({ clients }, { merge: true })
					.then(() => {
						console.log('COMPLETE UPDATE CLIENT');
						cb();
					})
					.catch((error) => console.log(error.message));
			} else console.log('No such document!');
		})
		.catch((error) => console.log('Error getting document:', error));
};

const addServiceToTechnician = (data, cb) => {
	const itemRefTechnician = firebaseFirestore.collection('users').doc(data.technician);
	itemRefTechnician
		.get()
		.then((doc) => {
			if (doc.exists) {
				const services = doc.data().services !== undefined ? doc.data().services : [];
				console.log(services);
				services.push(data.order);
				itemRefTechnician
					.set({ services }, { merge: true })
					.then(() => {
						console.log('COMPLETE ADD TECHNICIAN');
						cb();
					})
					.catch((error) => console.log(error.message));
			} else console.log('No such document!');
		})
		.catch((error) => console.log('Error getting document:', error));
};

const removeServiceToTechnician = (data, cb) => {
	const itemRefTechnician = firebaseFirestore.collection(`users`).doc(data.technician);

	itemRefTechnician
		.get()
		.then((doc) => {
			if (doc.exists) {
				const services = doc.data().services.filter((item) => item !== data.order);
				console.log(services);
				itemRefTechnician
					.set({ services }, { merge: true })
					.then(() => {
						console.log('COMPLETE UPDATE SERVICE IN TECHNICIAN');
						cb();
					})
					.catch((error) => console.log(error.message));
			} else console.log('No such document!');
		})
		.catch((error) => console.log('Error getting document:', error));
};

export const saveNewService = (id, type, data, cb) => {
	const itemRef = firebaseFirestore.collection('service').doc(id);
	itemRef
		.get()
		.then((doc) => {
			if (doc.exists) {
				itemRef
					.set(data)
					.then(() => cb(false))
					.catch((error) => {
						cb(true);
						console.log('Error getting document:', error);
					});
			} else {
				itemRef
					.set(data)
					.then(() => cb(false))
					.catch((error) => {
						cb(true);
						console.log('Error getting document:', error);
					});
			}
		})
		.catch((error) => {
			cb(true);
			console.log('Error getting document:', error);
		});
};

export const saveNewClient = (id, data, cb) => {
	// console.log(data);
	const itemRef = firebaseDatabase.ref(`clients/${id}`);
	itemRef.once('value', (snapshot) => {
		if (snapshot.exists()) {
			cb(true);
		} else {
			itemRef
				.set({ ...data })
				.then(() => cb(false))
				.catch((error) => console.log(error.message));

			firebaseDatabase
				.ref(`users/${data.technician}`)
				.child('services')
				.child(data.order)
				.set({ _id: data.order })
				.catch((error) => console.log(error.message));
		}
	});
};

export const getCurrentService = (id_client, cb) => {
	firebaseFirestore
		.collection(`service`)
		.doc(id_client)
		.get()
		.then((doc) => cb(doc.data()))
		.catch((error) => console.log(error.message));
};

export const deleteCurrentService = (service, cb) => {
	const itemRef = firebaseFirestore.collection(`service`).doc(service.order);

	itemRef
		.delete()
		.then(() => cb())
		.catch((error) => console.log(error.message));

	// removeServiceToTechnician(service);
};

export const updateCurrentClient = (id, data, cb) => {
	const itemRef = firebaseDatabase.ref(`clients/${id}`);

	itemRef.once('value', (snapshot) => {
		if (snapshot.exists()) {
			if (snapshot.val().technician !== data.technician) {
				console.log('Ha cambiado de tecnico exitosamente');

				firebaseDatabase
					.ref(`users/${snapshot.val().technician}`)
					.child('services')
					.child(id)
					.remove()
					.then(() => console.log('Técnico eliminado exitosamente'))
					.catch((error) => console.log(error.message));

				firebaseDatabase
					.ref(`users/${data.technician}`)
					.child('services')
					.child(id)
					.set({ _id: id })
					.catch((error) => console.log(error.message));
			}
		} else {
			console.log('NO EXISTE EN UPDATE');
		}

		itemRef
			.set(data)
			.then(() => cb())
			.catch((error) => console.log(error.message));
	});
};

// export const logout = (cb) => {
// 	setClient(null);
// 	cb();
// };

export const getCurrentClient = (type_collection, id_doc, cb) => {
	firebaseFirestore
		.collection(type_collection)
		.doc(id_doc)
		.get()
		.then((doc) => cb(doc.data()))
		.catch((error) => console.log(error.message));
};

export const getCurrentServiceMap = async (id_order, cb) => {
	const snapshot = await firebaseFirestore.collection('service').doc(id_order).collection('provider_location').get();

	const data = [];
	snapshot.forEach((doc) => data.push(doc.data()));
	cb(data);
};

export const login = (user) => firebaseAuth.signInWithEmailAndPassword(user.email, user.password);
export const logout = () => firebaseAuth.signOut();
export const resetPassword = (email) => firebaseAuth.sendPasswordResetEmail(email);
export const confirmResetPassword = (code, newPassword) => firebaseAuth.confirmPasswordReset(code, newPassword);

export const compareObj = (a, b) => {
	var aKeys = Object.keys(a).sort();
	var bKeys = Object.keys(b).sort();
	if (aKeys.length !== bKeys.length) {
		return false;
	}
	if (aKeys.join('') !== bKeys.join('')) {
		return false;
	}
	for (var i = 0; i < aKeys.length; i++) {
		if (a[aKeys[i]] !== b[bKeys[i]]) {
			return false;
		}
	}
	return true;
};

export const uploadFile = (file, path, onFileReady, progress) => {
	let imagesFilesRef = firebaseStorage.child(path);

	// console.log('uploading image');
	let task = imagesFilesRef.put(file.originFileObj);

	task.on('state_changed', (snapshot) => {
		let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
		progress(percentage);
	});

	task.then((child) => {
		imagesFilesRef.getDownloadURL().then((urlSnap) => {
			onFileReady(urlSnap);
		});
	});

	return task;
};

export const uuidv4 = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

export const removeServiceCurrentConductor = ({ id }, cb) => {
	// console.log(state);
	// console.log(_id);
	firebaseDatabase
		.ref('online')
		.child(id)
		.remove()
		.then(() => cb())
		.catch((err) => console.log(err));
};
