export const CLIENT_OBJECT_ID = 'client';
export const API_GOOGLE_KEY = 'AIzaSyDDO9EbDTQCSSfn2f00MjajI7gV3ied6xI';
// export const BASE_URL = 'https://blac-solutions.herokuapp.com';
export const BASE_URL = 'https://backend-blac.herokuapp.com';

export const SUPER_ROOT = 'lzoOKtUkn3NikocKBnhuLVXx82y2';

export const STATUS_PENDING_WORKER = 'STATUS_PENDING_WORKER';
export const WORKER_ACCEPTED = 'WORKER_ACCEPTED';
export const WORKER_ARRIVED = 'WORKER_ARRIVED';
export const WORKER_IN_PROGRESS = 'WORKER_IN_PROGRESS';
export const WORKER_REJECTED = 'WORKER_REJECTED';
export const WORKER_DONE = 'WORKER_DONE';

export const DATE_SUGGESTION_WORKER = 'DATE_SUGGESTION_WORKER';
export const HOUR_SUGGESTION_WORKER = 'HOUR_SUGGESTION_WORKER';
