import React, { Component } from 'react';
import { Modal, notification } from 'antd';
import { ComponentOkAction } from './ComponentOkAction';
import { ConfirmCancelModal } from './ConfirmCancelModal';
import { FormAddClient } from './FormAddClient';
import { compareObj, getClient, saveClient, updateClient, getAllFirtsCollections } from '../config/Helpers';

const normalizeString = (e) =>
	e
		.trim()
		.toUpperCase()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '');

export class AddClientToSystem extends Component {
	state = {
		admins: [],
		loading: false,
		visible: false,
		upload: false,
		messageError: '',
		itemCard: {},
		_itemCard: {},
		type: '',
		orderToShow: null,
		client: {
			name: '',
			id_admin: '',
			inputList: [
				{
					branch_office: '',
					address: {
						maps: {
							direction: '',
							location: '',
						},
					},
				},
			],
		},
		_client: {
			name: '',
			id_admin: '',
			inputList: [
				{
					branch_office: '',
					address: {
						maps: {
							direction: '',
							location: '',
						},
					},
				},
			],
		},
	};

	handleCancel = (e) => {
		// console.log(e);
		this.setState({ visible: false, orderToShow: null });
	};

	handleSelect = (value, type) => {
		this.setState({ [this.props.type]: { ...this.state[this.props.type], [type]: value } });
	};

	handleOnPlaceSelected = (place, index) => {
		if (place.formatted_address !== undefined) {
			const value = {
				maps: {
					direction: place.formatted_address,
					location: {
						lat: place.geometry.location.lat(),
						lon: place.geometry.location.lng(),
					},
				},
			};
			const inputList = [...this.state[this.props.type]['inputList']];
			inputList[index]['address'] = value;
			this.setState({
				[this.props.type]: {
					...this.state[this.props.type],
					inputList,
				},
			});
		}
	};

	handleInputChange = (event, index) => {
		const { name, value } = event.target;

		if (name === 'branch_office') {
			const inputList = [...this.state[this.props.type]['inputList']];
			inputList[index][name] = value;
			this.setState({
				[this.props.type]: {
					...this.state[this.props.type],
					inputList,
				},
			});
		}

		this.setState({ [this.props.type]: { ...this.state[this.props.type], [name]: value } });
	};

	handleAddClient = (data) => {
		saveClient(data, (_id) => {
			this.setState({ upload: false });
			this.cleanToStateStart('client');
			this.hiddenModal();
			this.props.onAddClient(data, _id);
			this.handleShowNotification('success', 'El cliente se agrego con exito');
		});
	};

	handleUpdateService = (data) => {
		updateClient(data._id, data, () => {
			this.setState({ upload: false });
			this.props.onUpdateClient(data);
			this.hiddenModal();
			this.cleanToStateStart('client');
			this.handleShowNotification('success', 'Cliente actualizado correctamente');
		});
	};

	hiddenModal = () => {
		this.props.onHiddenModal();
		this.cleanToStateStart('client');
	};

	handleShowNotification = (type, title) => {
		notification[type]({
			message: title,
			description: `Este cambio ya se ha aplicado en el sistema`,
		});
	};

	handleOk = async (e) => {
		if (this.state[this.props.type].name.length) {
			const isExistUserRegister = this.state.users.filter(
				(item) => normalizeString(item?.name) === normalizeString(this.state[this.props.type].name)
			);
			if (!isExistUserRegister.length) {
				if (this.state[this.props.type].id_admin.length) {
					const data = this.state[this.props.type].inputList.map((item) => {
						if (item.branch_office !== '' && item.address.maps.direction !== '') {
							return true;
						}

						return false;
					});
					let isCompleteAddress = data.indexOf(false) === -1;

					if (isCompleteAddress) {
						this.setState({ upload: true });

						setTimeout(() => {
							this.props.type === 'itemCard'
								? this.handleUpdateService({
										...this.state.itemCard,
										name: normalizeString(this.state[this.props.type].name),
								  })
								: this.handleAddClient({
										...this.state.client,
										name: normalizeString(this.state[this.props.type].name),
								  });
						}, 100);
					} else this.setState({ messageError: 'Ingresa la dirección de la sucursal' });
				} else this.setState({ messageError: 'Selecciona el administrador encargado del cliente' });
			} else this.setState({ messageError: 'Este cliente ya fue existe registrado en el sistema' });
		} else this.setState({ messageError: 'Ingresa el nombre del cliente' });
	};

	handleShowModal = (message) => {
		Modal.success({ title: 'Error', content: message });
	};

	async componentDidMount() {
		const { admins } = this.props;
		this.setState({ admins });

		if (this.props.itemCard !== null) {
			this.setState({ itemCard: this.props.itemCard, _itemCard: this.props.itemCard });
		}

		await this.handleGetAllClients();
	}

	handleGetAllClients = async () => {
		const users = await getAllFirtsCollections('clients');
		this.setState({ users });
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.type !== this.props.type) {
			this.cleanToStateStart(this.props.type);
		}

		if (prevProps.itemCard !== this.props.itemCard) {
			if (this.props.itemCard !== null) {
				this.setState({ itemCard: this.props.itemCard, _itemCard: this.props.itemCard });
			}
		}

		if (prevProps.visible !== this.props.visible) {
			if (this.props.visible === false) {
				this.cleanToStateStart(this.props.type);
			}
		}
	}

	cleanToStateStart = (node) => {
		const cleanToStateStart = { ...this.state._client, id_admin: getClient()._id };
		this.setState({
			[node]: cleanToStateStart,
			[`_${node}`]: cleanToStateStart,
		});
	};

	handleRemoveInput = (index) => {
		const inputList = [...this.state[this.props.type]['inputList']];
		inputList.splice(index, 1);
		this.setState({ [this.props.type]: { ...this.state[this.props.type], inputList } });
	};

	handleOnAddInput = () => {
		this.setState({
			[this.props.type]: {
				...this.state[this.props.type],
				inputList: [
					...this.state[this.props.type].inputList,
					{
						branch_office: '',
						address: {
							maps: {
								direction: '',
								location: '',
							},
						},
					},
				],
			},
		});
	};

	showItemCardModal() {
		let dataOne = compareObj(this.state.itemCard, this.state._itemCard);
		let dataTwo = JSON.stringify(this.state.client) === JSON.stringify(this.state._client);
		let isChange = dataOne === false || dataTwo === false;
		return (
			<Modal
				closable={false}
				destroyOnClose={true}
				title="Información del cliente"
				visible={this.props.visible}
				maskClosable={false}
				onOk={this.handleOk}
				okText={<ComponentOkAction upload={this.state.upload} />}
				okButtonProps={{ disabled: isChange ? false : true }}
				onCancel={isChange ? null : this.hiddenModal}
				cancelText={isChange ? <ConfirmCancelModal onConfirm={this.hiddenModal} onCancel={() => {}} /> : null}
			>
				<FormAddClient
					data={this.state[this.props.type]}
					admins={this.props.admins}
					type={this.props.type}
					messageError={this.state.messageError}
					onChange={this.handleInputChange}
					onRemoveInput={this.handleRemoveInput}
					onAddInput={this.handleOnAddInput}
					onSelect={this.handleSelect}
					onPlaceSelected={this.handleOnPlaceSelected}
					ref={(direction) => (this.direction = direction)}
				/>
			</Modal>
		);
	}

	render() {
		return this.state[this.props.type] !== undefined ? this.showItemCardModal() : null;
	}
}
