import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { HeaderDashboard } from '../../components/HeaderDashboard';
import { Logo } from '../../components/Logo';
import { ConfirmCancelModal } from '../../components/ConfirmCancelModal';
import { ComponentOkAction } from '../../components/ComponentOkAction';
import { OptionWithFilter } from '../../components/OptionWithFilter';
import { FormAddService } from '../../components/FormAddService';
import { TitleModalPdf } from '../../components/TitleModalPdf';
import { List, ListItem } from '../../components/ListElement';
import { compareObj, saveNewService, getAllData, deleteCurrentService, getClient } from '../../config/Helpers';
import { Table, Modal, notification, Menu, Icon, Divider, Avatar, Dropdown, DatePicker, Radio } from 'antd';
import {
	STATUS_PENDING_WORKER,
	WORKER_ACCEPTED,
	WORKER_ARRIVED,
	WORKER_IN_PROGRESS,
	WORKER_REJECTED,
	WORKER_DONE,
	SUPER_ROOT,
} from '../../constants/Constans';
import html2pdf from 'html2pdf.js';

const service_type = ['revision', 'instalacion', 'desinstalacion'];
const { RangePicker } = DatePicker;

export class ServicesWorks extends Component {
	state = {
		data: [],
		cloneData: [],
		data_installation_technician: [],
		clients: [],
		installation_technician: [],
		loading: false,
		itemCard: {},
		_itemCard: {},
		orderToShow: null,
		visible: false,
		showCurrentOrder: false,
		type: '',
		messageError: '',
		isWaitingData: false,
		filterWithDate: [],
		currentIndexPicture: 0,
		secondIndexPicture: 0,
		filterWithClient: '',
		filterInput: '',
		service: {
			order: '',
			priority: '',
			client: '',
			address: {
				maps: {
					direction: '',
					location: '',
				},
			},
			service_type: '',
			service_date: '',
			service_hour: '',
			installation_technician: '',
			status_task: 'PENDING',
			client_signature: null,
		},
		_service: {
			order: '',
			priority: '',
			client: '',
			address: {
				maps: {
					direction: '',
					location: '',
				},
			},
			service_type: '',
			service_date: '',
			service_hour: '',
			installation_technician: '',
			status_task: 'PENDING',
			client_signature: null,
		},
	};

	getCurrentFilter = () => {
		let status = '';
		switch (this.props.location.pathname) {
			case `/admin/servicios/pendiente`:
				status = STATUS_PENDING_WORKER;
				break;
			case `/admin/servicios/aceptado`:
				status = WORKER_ACCEPTED;
				break;
			case `/admin/servicios/hacia-destino`:
				status = WORKER_ARRIVED;
				break;
			case `/admin/servicios/progreso`:
				status = WORKER_IN_PROGRESS;
				break;
			case `/admin/servicios/rechazado`:
				status = WORKER_REJECTED;
				break;
			case `/admin/servicios/completado`:
				status = WORKER_DONE;
				break;
			default:
				status = '';
				break;
		}

		return status;
	};

	handleOnActionCard = (value, index) => {
		let validate = false;
		switch (value) {
			case 'view':
				validate = true;
				break;
			case 'edit':
				validate = true;
				break;

			default:
				validate = false;
				break;
		}

		if (validate) {
			this.getItemCard(index);
			this.setState({ type: 'itemCard' });
		} else {
			console.log('Eliminar producto');
			this.deleteItemCard(index);
			this.setState({ type: 'itemCard' });
			this.handleShowNotification('warning', 'Producto eliminado con exito');
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.visible !== this.state.visible) {
			if (this.state.visible === false && (Object.entries(this.state.service).length === 0) === false) {
				this.setState({ service: this.state._service });
			}
		}

		if (
			prevState.filterWithTechnician !== this.state.filterWithTechnician ||
			prevState.filterWithClient !== this.state.filterWithClient ||
			prevState.filterWithDate !== this.state.filterWithDate
		) {
			this.getDataFiltered();
		}

		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.getDataFiltered();
		}
	}

	componentDidMount() {
		// const { isAdmin } = this.props;
		// const client = getClient();

		const isSuperAdmin = getClient()?.isSuperRoot;

		getClient() &&
			getAllData('service', (services) => {
				const data = services.filter(
					(item) => {
						return !isSuperAdmin
							? (getClient().technicians || []).indexOf(item.installation_technician) !== -1
							: item;
					},
					[services]
				);

				this.setState({ data, cloneData: data }, () => {
					const currentStatus = this.getCurrentFilter();
					currentStatus !== '' && this.getDataFiltered();
				});

				// if (isAdmin) {
				// 	this.setState({ data: services, cloneData: services }, () => {
				// 		const currentStatus = this.getCurrentFilter();
				// 		currentStatus !== '' && this.getDataFiltered();
				// 	});
				// } else {
				// 	if (client) {
				// 		const data = services.filter((item) => item.installation_technician._id === client._id);

				// 		this.setState({ data, cloneData: data }, () => {
				// 			const currentStatus = this.getCurrentFilter();
				// 			currentStatus !== '' && this.getDataFiltered();
				// 		});
				// 	}
				// }
			});

		getAllData('clients', (data) => {
			const clients = data.filter(
				(item) => {
					return !isSuperAdmin ? (getClient().clients || []).indexOf(item._id) !== -1 : item;
				},
				[data]
			);

			this.setState({ clients });
		});
		getAllData('users', (data) => {
			const data_installation_technician = data.filter(
				(item) => {
					return !isSuperAdmin ? (getClient().technicians || []).indexOf(item._id) !== -1 : item;
				},
				[data]
			);
			this.setState({ installation_technician: data, data_installation_technician });
		});
	}

	getDataFiltered = () => {
		this.setState({ isWaitingData: true });

		// [startDate, endDate]
		// console.log(new Date(startDate));
		// console.log(new Date(endDate));

		// console.log(moment(startDate).valueOf());
		// console.log(moment(endDate).valueOf());

		const [startDate, endDate] = this.state.filterWithDate;
		const currentStatus = this.getCurrentFilter();
		// console.log(this.state.filterWithDate);
		// console.log(this.state.cloneData);
		// console.log(currentStatus);
		const filterDataByTable = this.state.cloneData.filter((item) => {
			if (item) {
				const isExistDateFilter = startDate !== '' && endDate !== '' && this.state.filterWithDate.length > 0;
				const date = new Date(item.service_date);
				const filterDate = isExistDateFilter && date >= new Date(startDate) && date <= new Date(endDate);
				const isExistClient = this.state.filterWithClient;
				const isExistTechnician = this.state.filterWithTechnician;
				const clientService =
					isExistClient && item.client._id && item.client._id.indexOf(this.state.filterWithClient);
				const technicianService =
					isExistTechnician && item.installation_technician.indexOf(this.state.filterWithTechnician);

				const isCurrentStatus = currentStatus !== '' ? item.status_task === currentStatus : true;

				const existsClient = !!~clientService;
				const existsTechnician = !!~technicianService;

				// return isExistDateFilter
				// 	? existsClient && existsTechnician && filterDate
				// 	: existsClient && existsTechnician;

				// console.log(isCurrentStatus);
				return isExistDateFilter
					? existsClient && existsTechnician && filterDate && isCurrentStatus
					: existsClient && existsTechnician && isCurrentStatus;
			}
		});

		// console.log(filterDataByTable);
		// console.log(this.state.filterWithDate);

		// const data = filterDataByTable.filter((item) => {
		// 	const date = new Date(item.service_date);
		// 	return date >= new Date(startDate) && date <= new Date(endDate);
		// });

		this.setState({ data: filterDataByTable, isWaitingData: false });
	};

	showModal = (orderToShow, type) => {
		// console.log(orderToShow);
		this.setState({ [type]: true, orderToShow });
	};

	getCurrentStatus = (status = '') => {
		let currentStatus;
		switch (status) {
			case STATUS_PENDING_WORKER:
				currentStatus = 'Pendiente';
				break;
			case WORKER_ACCEPTED:
				currentStatus = 'Aceptado';
				break;
			case WORKER_ARRIVED:
				currentStatus = 'Hacia el destino';
				break;
			case WORKER_IN_PROGRESS:
				currentStatus = 'En progreso';
				break;
			case WORKER_REJECTED:
				currentStatus = 'Rechazado';
				break;
			case WORKER_DONE:
				currentStatus = 'Completado';
				break;
			default:
				currentStatus = '---';
				break;
		}

		return currentStatus;
	};

	handleShowCurrentService = () => {
		this.props.history.push({ pathname: '/admin/servicios/nuevo', type: 'service' });
	};

	hiddenModal = () => {
		this.setState({ visible: false });
	};

	handleAddService = (data) => {
		saveNewService(data.order, data, (existService) => {
			if (existService) {
				Modal.error({ title: 'Ya existe este número de orden' });
				this.setState({ upload: false });
			} else {
				setTimeout(() => {
					this.setState({
						upload: false,
						visible: false,
						service: this.state._service,
					});
					let message =
						this.state.type === 'itemCard'
							? 'Servicio actualizado correctamente'
							: 'El servicio se agrego con exito';
					this.handleShowNotification('success', message);
				}, 1200);
			}
		});
	};

	handleUpdateService = (data) => {
		console.log(data);

		// updateCurrentService(data.order, data, () => {
		// 	setTimeout(() => {
		// 		// this.getAllProducts();
		// 		this.setState({
		// 			upload: false,
		// 			visible: false,
		// 			service: this.state._service,
		// 		});
		// 		let message = 'Servicio actualizado correctamente';
		// 		this.handleShowNotification('success', message);
		// 	}, 1200);
		// });
	};

	handleOk = (e) => {
		if (this.state[this.state.type].order.length) {
			if (this.state[this.state.type].priority.length) {
				if (this.state[this.state.type].client.length) {
					if (this.state[this.state.type].address.maps.direction.length) {
						if (this.state[this.state.type].service_type.length) {
							if (this.state[this.state.type].service_date.length) {
								if (this.state[this.state.type].service_hour.length) {
									if (this.state[this.state.type].installation_technician.length) {
										this.setState({ upload: true });

										setTimeout(() => {
											this.state.type === 'itemCard'
												? this.handleUpdateService(this.state.itemCard)
												: this.handleAddService(this.state.service);
										}, 100);
										console.log('Guardar...');
									} else {
										this.setState({
											messageError: 'Selecciona un técnico para realizar el servicio',
										});
									}
								} else {
									this.setState({ messageError: 'Selecciona la hora del servicio' });
								}
							} else {
								this.setState({ messageError: 'Selecciona la fecha del servicio' });
							}
						} else {
							this.setState({ messageError: 'Selecciona el tipo de servicio' });
						}
					} else {
						this.setState({ messageError: 'Ingresa la dirección del servicio' });
					}
				} else {
					this.setState({ messageError: 'Selecciona el cliente a realizar el servicio' });
				}
			} else {
				this.setState({ messageError: 'Selecciona la prioridad del servicio' });
			}
		} else {
			this.setState({ messageError: 'Ingresa el número de orden para el servicio' });
		}
	};

	handleShowNotification = (type, title) => {
		notification[type]({
			message: title,
			description: `Este cambio ya se ha aplicado en el sistema`,
		});
	};

	handleInputChange = (event) => {
		const { name, value } = event.target;

		if (name === 'discount' || name === 'price' || name === 'stock') {
			if (value.length <= 10) {
				if (!isNaN(value)) {
					this.setState({ [this.state.type]: { ...this.state[this.state.type], [name]: parseInt(value) } });
				}
			}
			if (!value.length) {
				this.setState({ [this.state.type]: { ...this.state[this.state.type], [name]: '' } });
			}
		} else {
			this.setState({ [this.state.type]: { ...this.state[this.state.type], [name]: value } });
		}
	};

	handleSelect = (value, type) => {
		// console.log(value);
		// console.log(type);

		if (type === 'installation_technician') {
			const data = this.state.installation_technician.filter((item) => item._id === value);
			if (data.length > 0) {
				const installation_technician = { ...data[0] };
				console.log(installation_technician);
				this.setState({
					[this.state.type]: {
						...this.state[this.state.type],
						[type]: installation_technician.name,
						technician: installation_technician._id,
					},
				});
			}
		} else {
			this.setState({ [this.state.type]: { ...this.state[this.state.type], [type]: value } });
		}
	};

	handleTableChange = (value) => {
		console.log(value);
	};

	handleOnChangeDate = (date, service_date) => {
		// console.log(date, service_date);
		this.setState({ [this.state.type]: { ...this.state[this.state.type], service_date } });
	};

	handleOnChangeTime = (time, service_hour) => {
		// console.log(time, service_hour);
		this.setState({ [this.state.type]: { ...this.state[this.state.type], service_hour } });
	};

	handleOnPlaceSelected = (place) => {
		if (place.formatted_address !== undefined) {
			this.setState({
				[this.state.type]: {
					...this.state[this.state.type],
					address: {
						...this.state[this.state.type].address,
						maps: {
							direction: place.formatted_address,
							location: {
								lat: place.geometry.location.lat(),
								lon: place.geometry.location.lng(),
							},
						},
					},
				},
			});
		}
	};

	handleOnSelectLocaties = (value) => {
		this.setState({ [this.state.type]: { ...this.state[this.state.type], ['client']: value } });
	};

	handleActionCurrentService = (record, type) => {
		switch (type) {
			case 'edit':
				console.log('EDIT');
				this.props.history.push({ pathname: `/admin/servicios/editar/${record.order}`, type: 'itemCard' });
				break;
			default:
				Modal.confirm({
					title: '¿Estas seguro de eliminar este servicio?',
					onOk: () => {
						deleteCurrentService(record, () => {
							console.log('SERVICIO ELIMINADO CON EXITO');
							const data = this.state.data.filter((item) => item.order !== record.order);
							this.setState({ data });
						});
					},
					onCancel: () => {},
					okText: 'Si, Eliminar',
					cancelText: 'Cancelar',
				});

				break;
		}
	};

	printDocument = (type) => {
		const isResumeOrder = type === 'resume';
		this.recipt.parentNode.style.overflow = 'visible';

		if (isResumeOrder) {
			const ref = document.getElementById('pictureShow');
			ref.style.display = 'none';
			// const refImage =
			// 	ref.nextSibling.lastElementChild.firstElementChild.lastElementChild.firstElementChild.firstElementChild;
			// refImage.style.display = 'block';
			// refImage.style.width = '100%';
			// refImage.style.height = '100%';
			// refImage.style.objectFit = 'contain';
		}

		this.setState({ visible: false });

		const opt = {
			margin: [10, 10, 40, 10],
			filename: 'document.pdf',
			image: { type: 'jpeg', quality: 0.95 },

			html2canvas: { scale: 1, dpi: 300, letterRendering: true, useCORS: true },
			jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait', compress: true },
			pagebreak: { mode: 'avoid-all', after: '.avoidThisRow' },
		};

		html2pdf().from(this.recipt).set(opt).save();
	};

	showItemCardModal() {
		let dataOne = compareObj(this.state.itemCard, this.state._itemCard);
		let dataTwo = JSON.stringify(this.state.service) === JSON.stringify(this.state._service);
		return (
			<Modal
				title="Información de servicio"
				visible={this.state.visible}
				maskClosable={false}
				onOk={this.handleOk}
				okText={<ComponentOkAction upload={this.state.upload} type={this.state.type} />}
				okButtonProps={{
					disabled: dataOne === false || dataTwo === false ? false : true,
				}}
				closable={false}
				onCancel={dataOne === false || dataTwo === false ? null : this.hiddenModal}
				cancelText={
					dataOne === false || dataTwo === false ? (
						<ConfirmCancelModal onOk={this.hiddenModal} onCancel={() => {}} />
					) : null
				}
			>
				<form onSubmit={(e) => e.preventDefault()} noValidate>
					<FormAddService
						ref={(direction) => (this.direction = direction)}
						data={this.state[this.state.type]}
						clients={this.state.clients}
						service_type={service_type}
						installation_technician={this.state.data_installation_technician}
						messageError={this.state.messageError}
						onChange={this.handleInputChange}
						onDate={this.handleOnChangeDate}
						onTime={this.handleOnChangeTime}
						onSelect={this.handleSelect}
						onPlaceSelected={this.handleOnPlaceSelected}
						onSelectLocaties={this.handleOnSelectLocaties}
					/>
				</form>
			</Modal>
		);
	}

	handleCancel = (type) => {
		this.setState({ [type]: false, orderToShow: null });
	};

	getFormatData = (data) => {
		if (data !== null) {
			const board_photo_car =
				data.board_photo_car !== null && data.board_photo_car !== undefined && data.board_photo_car.length
					? [{ type: 'Tablero del vehiculo', url: data.board_photo_car, isAvailable: true }]
					: [];
			const plate_photo_car =
				data.plate_photo_car !== null && data.plate_photo_car !== undefined && data.plate_photo_car.length
					? [{ type: 'Placa del vehiculo', url: data.plate_photo_car, isAvailable: true }]
					: [];
			const photo_costado_car =
				data.photo_costado_car !== null && data.photo_costado_car !== undefined && data.photo_costado_car.length
					? [{ type: 'Foto de costado', url: data.photo_costado_car, isAvailable: true }]
					: [];
			const board_release =
				data.board_release !== null && data.board_release !== undefined && data.board_release.length
					? [{ type: 'Tablero revisión', url: data.board_release, isAvailable: true }]
					: [];

			const work_area_release =
				data.work_area_release !== null && data.work_area_release !== undefined && data.work_area_release.length
					? [{ type: 'Área de trabajo', url: data.work_area_release, isAvailable: true }]
					: [];

			const initial_review =
				data.initial_review !== null && data.initial_review !== undefined && data.initial_review.length
					? data.initial_review
					: [];

			const installation_tests =
				data.installation_tests !== null &&
				data.installation_tests !== undefined &&
				data.installation_tests.length
					? data.installation_tests
					: [];

			return [
				...board_photo_car,
				...plate_photo_car,
				...photo_costado_car,
				...board_release,
				...work_area_release,
				...initial_review,
				...installation_tests,
			];
		}

		return [];
	};

	handleSelectPicture = ({ target }, type) => {
		console.log(target.value);
		this.setState({ [type]: target.value });
	};

	detailsOrderService() {
		const data = this.state.orderToShow;
		const _data = this.getFormatData(data);
		const all_images = _data.filter((item) => item.isAvailable && item.url.length);
		return (
			<Modal
				title={<TitleModalPdf onClick={() => this.printDocument('resume')} />}
				visible={this.state.showCurrentOrder}
				width={'80vh'}
				onOk={this.handleOk}
				onCancel={() => this.handleCancel('showCurrentOrder')}
				footer={null}
			>
				<div ref={(recipt) => (this.recipt = recipt)}>
					{this.state.orderToShow === null ? (
						<span></span>
					) : (
						<Grid>
							<Row middle="xs" style={{ paddingBottom: 16 }}>
								<Col xs={6}>
									<Logo maxWidth={108} />
								</Col>
								<Col xs={6}>
									<Row end="xs" middle="xs">
										<Col xs={12}>
											<h3>Número de orden {data.order !== null ? data.order : '---'}</h3>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row
								center="xs"
								middle="xs"
								style={{ padding: '16px 0', background: '#e5e5e5', margin: '0 0 16px 0' }}
							>
								<Col xs={12}>
									<p style={{ margin: 0 }}>ORDEN DE SERVICIO</p>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<p>Número de Folio de liberación: {data.unit_release}</p>
								</Col>
								<Col xs={12}>
									<p>{`Fecha de servicio : ${data.service_date} - Hora de servicio : ${data.service_hour}`}</p>
								</Col>
								<Col xs={12}>
									<p>{`Estatus de servicio : ${this.getCurrentStatus(
										data.status_task
									)} - Tipo de servicio : ${data.service_type}`}</p>
								</Col>
							</Row>
							<Row style={{ padding: '16px 0', background: '#e5e5e5', margin: '0 0 16px 0' }}>
								<Col xs={6}>
									<p>Cliente: {data.client.name}</p>
								</Col>
								<Col xs={6}>
									<p>Empresa: {''}</p>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<p>Dirección: {data.address.maps.direction}</p>
								</Col>
							</Row>
							<Row style={{ padding: '16px 0', background: '#e5e5e5', margin: '0 0 16px 0' }}>
								<Col xs={6}>
									<p>Número de placa: {data.plate_number_car}</p>
								</Col>
								<Col xs={6}>
									<p>Modelo: {data.model_car}</p>
								</Col>
							</Row>
							<Row>
								<Col xs={6}>
									<p>Año: {data.year_car}</p>
								</Col>
								<Col xs={6}>
									<p>Número Vim: {data.vin_number_car}</p>
								</Col>
							</Row>
							<Row style={{ padding: '16px 0', background: '#e5e5e5', margin: '0 0 16px 0' }}>
								<Col xs={6}>
									<p>Número economico: {data.economic_number_car}</p>
								</Col>
								<Col xs={6}>
									<p>Kilometros: {data.kilometres_car}</p>
								</Col>
							</Row>
							<Row>
								<Col xs={6}>
									<p>Marca: {data.brand_car}</p>
								</Col>
								<Col xs={6}>
									<p>
										Técnico:{' '}
										{data.installation_technician?.name ||
											data.installation_technician_meta_data?.name}
									</p>
								</Col>
							</Row>

							<Row style={{ padding: '16px 0', background: '#e5e5e5', margin: '0 0 16px 0' }}>
								<Col xs={6}>
									<p>Comentarios: {data.comments}</p>
								</Col>
							</Row>

							<Row id="pictureShow">
								<Col xs={12}>
									<List className="scrollbar style-red w-100">
										<Radio.Group
											onChange={(e) => this.handleSelectPicture(e, 'currentIndexPicture')}
										>
											{_data.map((item, key) => {
												return (
													<ListItem key={key}>
														<Radio value={key}>
															<Avatar
																src={item.url}
																shape="square"
																style={{ width: 128, height: 128 }}
															/>
														</Radio>
													</ListItem>
												);
											})}
										</Radio.Group>
									</List>
								</Col>
								<Col xs={12}>
									<List className="scrollbar style-red w-100">
										<Radio.Group
											onChange={(e) => this.handleSelectPicture(e, 'secondIndexPicture')}
										>
											{_data.map((item, key) => {
												return (
													<ListItem key={key}>
														<Radio value={key}>
															<Avatar
																src={item.url}
																shape="square"
																style={{ width: 128, height: 128 }}
															/>
														</Radio>
													</ListItem>
												);
											})}
										</Radio.Group>
									</List>
								</Col>
							</Row>

							<Row>
								<Col xs={12}>
									<Row>
										<Col xs={12}>
											<p>Firma del cliente</p>
											<Avatar
												shape="square"
												src={data.client_signature || ''}
												size={260}
												style={{ height: 'auto' }}
												className="wrapper-firm-user"
											/>
										</Col>
									</Row>
								</Col>
								<Col xs={12}>
									<Row>
										<Col xs={12}>
											<p>Evidencias 1 </p>
										</Col>
										<Col xs={12}>
											<Avatar
												shape="square"
												src={
													all_images[this.state.currentIndexPicture]
														? all_images[this.state.currentIndexPicture].url
														: ''
												}
												style={{ height: 'auto' }}
												size={260}
											/>
										</Col>
									</Row>
								</Col>
								<Col xs={12}>
									<Row>
										<Col xs={12}>
											<p>Evidencias 2</p>
										</Col>
										<Col xs={12}>
											<Avatar
												shape="square"
												src={
													all_images[this.state.secondIndexPicture]
														? all_images[this.state.secondIndexPicture].url
														: ''
												}
												style={{ height: 'auto' }}
												size={260}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						</Grid>
					)}
				</div>
			</Modal>
		);
	}

	detailsClientModal() {
		const data = this.state.orderToShow;
		const _data = this.getFormatData(data);
		const all_images = _data.filter((item) => item.isAvailable && item.url.length);

		return (
			<Modal
				title={<TitleModalPdf onClick={() => this.printDocument('default')} />}
				visible={this.state.visible}
				width={'80vh'}
				onOk={this.handleOk}
				onCancel={() => this.handleCancel('visible')}
				footer={null}
			>
				<div ref={(recipt) => (this.recipt = recipt)}>
					{this.state.orderToShow === null ? (
						<span></span>
					) : (
						<Grid>
							<Row middle="xs" style={{ paddingBottom: 16 }}>
								<Col xs={6}>
									<Logo maxWidth={108} />
								</Col>
								<Col xs={6}>
									<Row end="xs" middle="xs">
										<Col xs={12}>
											<h3>Número de orden {data.order !== null ? data.order : '---'}</h3>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<p>Número de Folio de liberación: {data.unit_release}</p>
								</Col>
								<Col xs={12}>
									<p>Número de orden: {data.order}</p>
								</Col>
								<Col xs={12}>
									<p>Id técnico: {data.technician || data.installation_technician}</p>
								</Col>
								<Col xs={6}>
									<p>Cliente: {data.client.name}</p>
								</Col>
								<Col xs={6}>
									<p>
										Técnico:{' '}
										{data.installation_technician?.name ||
											data.installation_technician_meta_data?.name}
									</p>
								</Col>
								<Col xs={6}>
									<p>Prioridad: {data.priority}</p>
								</Col>
								<Col xs={6}>
									<p>Estatus de servicio : {this.getCurrentStatus(data.status_task)}</p>
								</Col>
								<Col xs={12}>
									<p>Tipo de servicio : {data.service_type}</p>
								</Col>
								<Col xs={12}>
									<p>Dirección: {data.address.maps.direction}</p>
								</Col>
							</Row>
							<Divider />
							<Row>
								<Col xs={6}>
									<p>Fecha de servicio : {data.service_date}</p>
								</Col>
								<Col xs={6}>
									<p>Hora de servicio : {data.service_hour}</p>
								</Col>
							</Row>
							<Divider />
							<Row>
								<Col xs={6}>
									<p>Marca: {data.brand_car}</p>
								</Col>
								<Col xs={6}>
									<p>Número economico: {data.economic_number_car}</p>
								</Col>
								<Col xs={6}>
									<p>Energía: {data.energy_car}</p>
								</Col>
								<Col xs={6}>
									<p>Horas de motor: {data.engine_hours_car}</p>
								</Col>
								<Col xs={6}>
									<p>Kilometros: {data.kilometres_car}</p>
								</Col>
								<Col xs={6}>
									<p>Modelo: {data.model_car}</p>
								</Col>
								<Col xs={6}>
									<p>Sub Modelo: {data.sub_model_car}</p>
								</Col>
								<Col xs={6}>
									<p>Tracción: {data.traction_car}</p>
								</Col>
								<Col xs={6}>
									<p>Número Vim: {data.vin_number_car}</p>
								</Col>
								<Col xs={6}>
									<p>Número de placa: {data.plate_number_car}</p>
								</Col>
								<Col xs={6}>
									<p>Año: {data.year_car}</p>
								</Col>
								<Col xs={12}>
									<p>Comentarios: {data.comments}</p>
								</Col>
								<Col xs={12}>
									<p>Otras explicaciones: {data.explanation}</p>
								</Col>
							</Row>

							<Row>
								{all_images.map((item, key) => {
									return (
										<Col xs={6} key={key}>
											<Row>
												<Col xs={12}>
													<p>{item.type}</p>
												</Col>
												<Col xs={12}>
													<Avatar
														shape="square"
														src={item.url}
														size={260}
														style={{ height: 'auto' }}
													/>
												</Col>
											</Row>
										</Col>
									);
								})}
							</Row>
						</Grid>
					)}
				</div>
			</Modal>
		);
	}

	handleonChangeDateRange = (date, stringDate) => {
		this.setState({ filterWithDate: stringDate });
	};

	render() {
		// console.log(this.state);

		const columns = [
			{
				title: 'Orden',
				dataIndex: 'order',
				// fixed: 'left',
				width: 160,
			},
			{
				title: 'Dirección',
				dataIndex: 'address',
				render: (value, data) => {
					if (!data.address.maps || !data.address.maps.direction) {
						return '---';
					}
					return data.address.maps.direction.toUpperCase();
				},
			},
			{
				title: 'Fecha',
				dataIndex: 'service_date',
			},
			{
				title: 'Hora',
				dataIndex: 'service_hour',
			},
			{
				title: 'Tipo de servicio',
				dataIndex: 'service_type',
				render: (value, data) => {
					if (data.service_type === undefined) {
						return '---';
					}
					return data.service_type.toUpperCase();
				},
				filters: [
					{ text: 'Revisión', value: 'revision' },
					{ text: 'Instalación', value: 'instalacion' },
					{ text: 'Desinstalación', value: 'desinstalacion' },
				],
				onFilter: (value, record) => record.service_type.includes(value),
			},
			{
				title: 'Prioridad',
				dataIndex: 'priority',
				render: (value, data) => {
					if (data.priority === undefined) {
						return '---';
					}
					return data.priority.toUpperCase();
				},
				filters: [
					{ text: 'Baja', value: 'baja' },
					{ text: 'Media', value: 'media' },
					{ text: 'Alta', value: 'alta' },
				],
				onFilter: (value, record) => record.priority.includes(value),
			},
			{
				title: 'Instalador',
				dataIndex: 'installation_technician',
				render: (value, record) => {
					if (!record.installation_technician_meta_data || !record.installation_technician_meta_data.name) {
						return '---';
					}
					return record.installation_technician_meta_data.name.toUpperCase();
				},
			},
			{
				title: 'Estado',
				dataIndex: 'status_task',
				render: (value, data) => this.getCurrentStatus(data.status_task),
				filters: [
					{ text: 'Pendiente', value: STATUS_PENDING_WORKER },
					{ text: 'Aceptado', value: WORKER_ACCEPTED },
					{ text: 'Hacia el destino', value: WORKER_ARRIVED },
					{ text: 'En progreso', value: WORKER_IN_PROGRESS },
					{ text: 'Rechazado', value: WORKER_REJECTED },
					{ text: 'Completado', value: WORKER_DONE },
				],
				onFilter: (value, record) => record.status_task.toString().includes(value),
			},
			{
				title: 'Cliente',
				dataIndex: 'client',
				render: (value, record) => {
					if (!record.client || !record.client.name) {
						return '---';
					}

					return record.client.name.toUpperCase();
				},
			},
			{
				index: 'action',
				width: 260,
				render: (value, record) => (
					<Row center="xs" middle="xs">
						<Col xs={12}>
							<Dropdown.Button
								overlay={
									<Menu>
										<Menu.Item key="1">
											<a href={null} onClick={() => this.showModal(record, 'showCurrentOrder')}>
												<Icon type="file-pdf" style={{ paddingRight: 8 }} />
												Descargar Orden de servicio
											</a>
										</Menu.Item>
										<Menu.Item key="2">
											<a href={null} onClick={() => this.showModal(record, 'visible')}>
												<Icon type="file-pdf" style={{ paddingRight: 8 }} />
												Descargar PDF
											</a>
										</Menu.Item>
										<Menu.Item key="3">
											<a
												href={null}
												onClick={() => this.handleActionCurrentService(record, 'edit')}
											>
												<Icon type="edit" style={{ paddingRight: 8 }} />
												Editar
											</a>
										</Menu.Item>
										<Menu.Item key="4">
											<a
												href={null}
												onClick={() => this.handleActionCurrentService(record, 'delete')}
											>
												<Icon type="delete" style={{ paddingRight: 8 }} />
												Eliminar
											</a>
										</Menu.Item>
									</Menu>
								}
								icon={<Icon type="more" />}
							>
								Opciones
							</Dropdown.Button>
						</Col>
					</Row>
				),
			},
		];

		return (
			<Grid fluid>
				<Row style={{ width: '100%', margin: 'auto' }}>
					<Col xs={12}>
						<HeaderDashboard
							extra={this.props.isAdmin}
							title="Mis servicios"
							onClick={this.handleShowCurrentService}
							textBtn="NUEVO SERVICIO"
						/>
						<Row style={{ padding: '16px 0' }}>
							<Col md={4}>
								<RangePicker onChange={this.handleonChangeDateRange} size="large" />
							</Col>
							<OptionWithFilter
								md={4}
								propValue={'_id'}
								propLabel={'name'}
								placeholder={'Filtra por cliente'}
								options={this.state.clients || []}
								onSelect={(filterWithClient) => this.setState({ filterWithClient })}
								onClearFilter={() => this.setState({ filterWithClient: '' })}
								disabled={this.state.isWaitingData}
							/>
							<OptionWithFilter
								md={4}
								propValue={'_id'}
								propLabel={'name'}
								placeholder={'Filtra por instalador'}
								options={this.state.data_installation_technician || []}
								onSelect={(filterWithTechnician) => this.setState({ filterWithTechnician })}
								onClearFilter={() => this.setState({ filterWithTechnician: '' })}
								disabled={this.state.isWaitingData}
							/>
						</Row>
						<Row className="pt-1">
							<Col xs={12}>
								<Table
									bordered
									columns={columns}
									rowKey={(record) => record.order}
									scroll={{ x: 'calc(880px + 100%)', y: 800 }}
									dataSource={this.state.data}
									pagination={{ pageSize: 10 }}
									loading={this.state.loading}
									onChange={this.handleTableChange}
								/>
							</Col>
						</Row>
						{this.detailsClientModal()}
						{this.detailsOrderService()}
					</Col>
				</Row>
			</Grid>
		);
	}
}
