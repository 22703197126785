import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { HeaderDashboard } from '../../components/HeaderDashboard';
import { Logo } from '../../components/Logo';
import { TitleModalPdf } from '../../components/TitleModalPdf';
import { Table, Modal, Button, Popconfirm, Alert, notification, Divider } from 'antd';
import { getCurrentClient, getAllData } from '../../config/Helpers';
import html2pdf from 'html2pdf.js';

const columns = [
	{
		title: 'Orden',
		dataIndex: 'order',
		width: 132,
	},
	{
		title: 'Dirección',
		dataIndex: 'address',
		render: (value, data) => data.address.maps.direction.toUpperCase(),
	},
	{
		title: 'Fecha',
		dataIndex: 'service_date',
	},
	{
		title: 'Hora',
		dataIndex: 'service_hour',
	},
	{
		title: 'Tipo de servicio',
		dataIndex: 'service_type',
		render: (value, data) => data.service_type.toUpperCase(),
		filters: [
			{ text: 'Revisión', value: 'revision' },
			{ text: 'Instalación', value: 'instalacion' },
			{ text: 'Desinstalación', value: 'desinstalacion' },
		],
		onFilter: (value, record) => record.service_type.includes(value),
	},
	{
		title: 'Prioridad',
		dataIndex: 'priority',
		render: (value, data) => data.priority.toUpperCase(),
		filters: [
			{ text: 'Baja', value: 'baja' },
			{ text: 'Media', value: 'media' },
			{ text: 'Alta', value: 'alta' },
		],
		onFilter: (value, record) => record.priority.includes(value),
	},
	{
		title: 'Instalador',
		dataIndex: 'installation_technician',
	},
	{
		title: 'Estado',
		dataIndex: 'status_task',
		render: (value, data) => (data.status_task === 'PENDING' ? 'PENDIENTE' : 'COMPLETADO'),
		filters: [
			{ text: 'Pendiente', value: 'PENDING' },
			{ text: 'Completado', value: 'COMPLETE' },
		],
		onFilter: (value, record) => record.status_task.toString().includes(value),
	},
	// {
	// 	title: 'Cliente',
	// 	dataIndex: 'client',
	// 	render: () => this.state.currentClient.name,
	// },
	// {
	// 	index: 'action',
	// 	width: 260,
	// 	render: (value, record) => (
	// 		<Row center="xs" middle="xs">
	// 			<Button
	// 				type="primary"
	// 				shape="round"
	// 				icon="edit"
	// 				size={'large'}
	// 				style={{ marginRight: 8 }}
	// 				onClick={() => this.handleActionCurrentService(record, 'edit')}
	// 			/>
	// 			<Popconfirm
	// 				title="¿Estas seguro de eliminar este servicio?"
	// 				onConfirm={() => this.handleActionCurrentService(record, 'delete')}
	// 				onCancel={() => {}}
	// 				okText="Si, Eliminar"
	// 				cancelText="Cancelar"
	// 			>
	// 				<Button
	// 					type="danger"
	// 					shape="round"
	// 					icon="delete"
	// 					size={'large'}
	// 					style={{ marginLeft: 8 }}
	// 				/>
	// 			</Popconfirm>
	// 		</Row>
	// 	),
	// },
];

export class CurrentClientsInTheSystem extends Component {
	state = {
		currentClient: null,
		data: [],
		users: [],
		loading: false,
		visible: false,
	};

	handleTableChange = (value) => {
		console.log(value);
	};

	showModal = () => {
		this.setState({ visible: true });
	};

	handleCancel = (e) => {
		this.setState({ visible: false });
	};

	printDocument = () => {
		this.recipt.parentNode.style.overflow = 'visible';
		this.setState({ visible: false });
		// this.recipt.firstChild.style.transform = '2em 4em';

		const opt = {
			margin: [10, 10, 10, 10],
			filename: 'document.pdf',
			image: { type: 'jpg' },
			html2canvas: { scale: 4 },
			jsPDF: { unit: 'mm', format: 'letter', orientation: 'landscape' },
		};

		html2pdf().from(this.recipt).set(opt).save();
	};

	detailsClientModal() {
		const { currentClient } = this.state;

		const columnsPDF = [
			{
				title: 'Orden',
				dataIndex: 'order',
				width: 132,
			},
			{
				title: 'Dirección',
				dataIndex: 'address',
				render: (value, data) => data.address.maps.direction.toUpperCase(),
			},
			{
				title: 'Fecha',
				dataIndex: 'service_date',
			},
			{
				title: 'Hora',
				dataIndex: 'service_hour',
			},
			{
				title: 'Tipo de servicio',
				dataIndex: 'service_type',
				render: (value, data) => data.service_type.toUpperCase(),
			},
			{
				title: 'Prioridad',
				dataIndex: 'priority',
				render: (value, data) => data.priority.toUpperCase(),
			},
			{
				title: 'Instalador',
				dataIndex: 'installation_technician',
				render: (e, record) => {
					const data = this.state.users.filter((item) => item._id === record.installation_technician);
					return data.length && data[0].name;
				},
			},
			{
				title: 'Estado',
				dataIndex: 'status_task',
				render: (value, data) => (data.status_task === 'PENDING' ? 'PENDIENTE' : 'COMPLETADO'),
			},
		];
		return (
			<Modal
				title={<TitleModalPdf onClick={this.printDocument} />}
				visible={this.state.visible}
				onOk={this.handleOk}
				onCancel={this.handleCancel}
				width={'80vw'}
				footer={null}
			>
				<div ref={(recipt) => (this.recipt = recipt)}>
					{!this.state.data.length ? (
						<span></span>
					) : (
						<Grid>
							<Row middle="xs" style={{ paddingBottom: 16 }}>
								<Col xs={6}>
									<Logo maxWidth={108} />
								</Col>
								<Col xs={6}>
									<Row end="xs" middle="xs">
										<Col xs={6}>
											<h3>Servicios de {currentClient !== null ? currentClient.name : '---'}</h3>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="pt-1 wrapper-table--pdf">
								<Col xs={12}>
									<Table
										bordered
										size="small"
										columns={columnsPDF}
										rowKey={(record) => record.order}
										dataSource={this.state.data}
										pagination={{ pageSize: 100000 }}
									/>
								</Col>
							</Row>
						</Grid>
					)}
				</div>
			</Modal>
		);
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id !== undefined) {
			getCurrentClient('clients', id, (currentClient) => {
				this.setState({ currentClient });
			});

			getAllData('service', (result) => {
				const data = result.filter((item) => item.client === id);
				this.setState({ data });
			});

			getAllData('users', (result) => {
				const users = result.filter((item) => item.type === 'TECNICO');
				this.setState({ users });
			});
		}
	}

	render() {
		const { currentClient } = this.state;
		return (
			<Grid fluid>
				<Row style={{ width: '100%', margin: 'auto' }}>
					<Col xs={12}>
						<HeaderDashboard
							title={currentClient !== null ? currentClient.name : '---'}
							extra
							onClick={this.showModal}
							textBtn={'IMPRIMIR SERVICIOS'}
						/>
						<Row className="pt-1">
							<Col xs={12}>
								<Table
									bordered
									columns={columns}
									rowKey={(record) => record.order}
									dataSource={this.state.data}
									pagination={{ pageSize: 10 }}
									scroll={{ x: 'calc(880px + 100%)', y: 800 }}
									loading={this.state.loading}
									onChange={this.handleTableChange}
								/>
							</Col>
						</Row>
						{this.detailsClientModal()}
					</Col>
				</Row>
			</Grid>
		);
	}
}
