import React, { useState, useEffect } from 'react';
import { Menu, Icon, Avatar, Drawer, Button } from 'antd';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import { logout, getClient, setClient } from '../config/Helpers';
import { Logo } from './Logo';

const { SubMenu } = Menu;

const admin = [
	{
		submenu: 'Dashboard',
		icon: 'dashboard',
		childrens: [
			{ key: '1', label: 'Inicio', to: '/admin' },
			{ key: '2', label: 'Mapa en vivo', to: '/admin/mapa' },
			{ key: '3', label: 'Calendario', to: '/admin/calendario' },
		],
	},
	{
		submenu: 'Servicios',
		icon: 'list',
		childrens: [
			{ key: 'c', label: 'Mis Servicios', to: '/admin/servicios' },
			{ key: '4', label: 'Estado Pendiente', to: '/admin/servicios/pendiente' },
			{ key: 'mamamama', label: 'Estado Aceptado', to: '/admin/servicios/aceptado' },
			{ key: 'b', label: 'Estado Hacia el destino', to: '/admin/servicios/hacia-destino' },
			{ key: 'd', label: 'Estado En Progreso', to: '/admin/servicios/progreso' },
			{ key: 'e', label: 'Estado Rechazado', to: '/admin/servicios/rechazado' },
			{ key: 'f', label: 'Estado Completado', to: '/admin/servicios/completado' },
		],
	},
	{
		submenu: 'Usuarios',
		icon: 'contacts',
		childrens: [
			{ key: 'xsas', label: 'Lista de tecnicos', to: '/admin/tecnicos' },
			{ key: '5', label: 'Lista de administradores', to: '/admin/administradores' },
		],
	},
	{
		submenu: 'Clientes',
		icon: 'medicine-box',
		childrens: [{ key: '6', label: 'Lista de Clientes', to: '/admin/clientes' }],
	},
	{
		submenu: 'Configuración',
		icon: 'setting',
		childrens: [{ key: '9', label: 'Cerrar sesión', to: '', onClick: true }],
	},
];

const technical = [
	{
		submenu: 'Dashboard',
		icon: 'dashboard',
		childrens: [
			{ key: '1', label: 'Calendario', to: '/admin/calendario' },
			{ key: '2', label: 'Mis Servicios', to: '/admin/servicios' },
		],
	},
	{
		submenu: 'Configuración',
		icon: 'setting',
		childrens: [{ key: '9', label: 'Cerrar sesión', to: '', onClick: true }],
	},
];

export const Sidebar = (props) => {
	const [state, setstate] = useState({ openKeys: ['sub1', 'sub2', 'sub3', 'sub4', 'sub5'], admin: null });
	const [isShowDrawer, setShowDrawer] = useState(false);
	const [currentKeys, setCurrentKeys] = useState('');
	const [currentData, setData] = useState([]);
	const [mode, setMode] = useState('inline');
	const { isAdmin } = props;
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		const getDimensions = () => {
			let mode = window.innerWidth < 999 ? 'horizontal' : 'inline';
			setMode(mode);
		};

		const reedirectPage = () => {
			history.push('/admin/calendario');
		};

		const getCurrentKeys = (type, currentItem) => {
			const joinItems = type.reduce((acc, el) => [...acc].concat(el.childrens), []);
			const data = joinItems.filter((item) => item.to === currentItem);
			// const filterData = joinItems.filter((item) => currentItem.indexOf(item.to) > -1);
			// console.log(data);
			// console.log(filterData);
			// if (!data.length) return history.push('/admin');
			if (!data.length) return '';
			return data[0].key;
		};

		const getCurrentKey = getCurrentKeys(isAdmin ? admin : technical, location.pathname);
		setCurrentKeys(getCurrentKey);

		getDimensions();

		setData(isAdmin ? admin : technical);
		if (!isAdmin) {
			reedirectPage();
		}
		setstate({ ...state, admin: getClient() });

		window.addEventListener('resize', getDimensions);
		return () => window.removeEventListener('resize', getDimensions);
	}, [mode, location.pathname]);

	const handleOnSelectChange = (openKeys) => {
		setstate({ ...state, openKeys });
	};

	const handleLogout = () => {
		logout()
			.then(() => {
				setClient(null);
				setTimeout(window.location.reload(), 100);
			})
			.catch((err) => console.log(err));
	};

	const onClose = () => {
		setShowDrawer(false);
	};

	const showDrawer = () => {
		setShowDrawer(true);
	};

	let WrapperContent;
	switch (mode) {
		case 'inline':
			WrapperContent = DefaultWrapper;
			break;
		case 'horizontal':
			WrapperContent = DrawerWrapper;
			break;
		default:
			console.log('aa');
			break;
	}

	const isExistUser = state.admin !== null && state.admin !== undefined;
	return (
		<WrapperContent showDrawer={showDrawer} onClose={onClose} visible={isShowDrawer}>
			<Row center="xs" className="m-none">
				<Col xs={12} style={{ maxWidth: 150, padding: '2em 1em' }}>
					<Logo />
				</Col>
			</Row>
			<Row center="xs" style={{ width: `${mode === 'inline' ? '260px' : '100%'}` }}>
				<Col xs={12} style={{ padding: '0 0 1em 0' }}>
					<Avatar style={{ color: 'white', background: '#4285F4', fontSize: 32 }} size={132}>
						{isExistUser ? state.admin.name.substr(0, 1).toUpperCase() : '---'}
					</Avatar>
				</Col>
				<Col xs={12}>
					<p>{isExistUser ? state.admin.name.toUpperCase() : '---'}</p>
				</Col>
				<Col xs={12}>
					<p style={{ color: '#4285F4' }}>{isAdmin ? 'Administrador' : 'Técnico'}</p>
				</Col>
			</Row>
			<Menu
				style={{
					width: `${mode === 'inline' ? '260px' : '100%'}`,
					overflow: `${mode === 'inline' ? null : 'auto'}`,
				}}
				openKeys={state.openKeys}
				onOpenChange={handleOnSelectChange}
				selectedKeys={currentKeys}
				mode={'inline'}
			>
				{currentData.map((item, key) => (
					<SubMenu
						key={`sub${key + 1}`}
						title={
							<span>
								<Icon type={item.icon} theme="twoTone" />
								<span>{item.submenu}</span>
							</span>
						}
					>
						{item.childrens.map((item) =>
							item.onClick ? (
								<Menu.Item key={item.key}>
									<NavLink to={item.to} onClick={handleLogout}>
										{item.label}
									</NavLink>
								</Menu.Item>
							) : (
								<Menu.Item key={item.key}>
									<NavLink to={item.to}>{item.label}</NavLink>
								</Menu.Item>
							)
						)}
					</SubMenu>
				))}
			</Menu>
		</WrapperContent>
	);
};

const DrawerWrapper = (props) => (
	<nav className="menuBar">
		<Col xs={12}>
			<Row>
				<Col xs={6}>
					<Row start="xs" middle="xs">
						<Col xs={12}>
							<h4>Panel de Blac Solutions</h4>
						</Col>
					</Row>
				</Col>
				<Col xs={6}>
					<Row end="xs">
						<Col xs={12}>
							<Button type="primary" onClick={props.showDrawer} style={{ margin: 0 }}>
								<Icon type="menu-fold" style={{ color: 'white' }} />
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
		<Drawer
			title="Menú"
			placement="right"
			closable={false}
			onClose={props.onClose}
			visible={props.visible}
			closable
		>
			{props.children}
		</Drawer>
	</nav>
);

const DefaultWrapper = (props) => {
	return <div>{props.children}</div>;
};
