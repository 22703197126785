import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { InputNormal } from './InputNormal';
import { Upload, Modal, Icon, Divider, Switch, Card } from 'antd';
const { Dragger } = Upload;

const { Meta } = Card;
// const { Option } = Select;

const getDocumentsAvailables = (entry, props, currentItem) => {
	// console.log(props.state[entry]);
	// console.log(props.state[entry].map((el) => el.type).indexOf(currentItem) !== -1);
	// console.log(currentItem);
	try {
		if (props.state[entry].map((el) => el.type).indexOf(currentItem) !== -1) {
			const currentDocument = props.data[entry].filter((el) => {
				return el.type === currentItem && el.isAvailable;
			});
			return currentDocument;
		}
	} catch (error) {
		console.log('Cargando....');
		return [];
	}
};

export const VehicleInformationForm = (props) => {
	const [typeDocument, setTypeDocument] = useState({ type: '', item: null });
	// const [reviews, setReviews] = useState(initialReview);

	// const handleOnSelect = useCallback((value, other, type) => {
	// 	props.onSelectItemOfArray(value, type);
	// 	// setReviews(value);
	// }, []);

	// useEffect(() => handleOnSelect(), []);
	// console.log(props);
	return (
		<>
			<HeaderSection title="Información del vehiculo" description="" />
			<Col xs={12}>
				<Row>
					<Col xs={12}>
						<Row start="xs">
							<InputNormal
								md={4}
								label={`Marca`}
								name={`brand_car`}
								value={props.data.brand_car || ''}
								onChange={props.onChange}
							/>
							<InputNormal
								md={4}
								label={`Modelo`}
								name={`model_car`}
								value={props.data.model_car || ''}
								onChange={props.onChange}
							/>
							<InputNormal
								md={4}
								label={`Sub modelo`}
								name={`sub_model_car`}
								value={props.data.sub_model_car || ''}
								onChange={props.onChange}
							/>
							<InputNormal
								md={4}
								label={`Año`}
								name={`year_car`}
								value={props.data.year_car || ''}
								onChange={props.onChange}
							/>
							<InputNormal
								md={4}
								label={`Kilometros`}
								name={`kilometres_car`}
								value={props.data.kilometres_car || ''}
								onChange={props.onChange}
							/>
							<InputNormal
								md={4}
								label={`Horas de motor`}
								name={`engine_hours_car`}
								value={props.data.engine_hours_car || ''}
								onChange={props.onChange}
							/>
							<InputNormal
								md={4}
								label={`Energía`}
								name={`energy_car`}
								value={props.data.energy_car || ''}
								onChange={props.onChange}
							/>
							<InputNormal
								md={4}
								label={`Tracción`}
								name={`traction_car`}
								value={props.data.traction_car || ''}
								onChange={props.onChange}
							/>
							<InputNormal
								md={4}
								label={`Número economico`}
								name={`economic_number_car`}
								value={props.data.economic_number_car || ''}
								onChange={props.onChange}
							/>
							<InputNormal
								md={6}
								label={`Número de Placa`}
								name={`plate_number_car`}
								value={props.data.plate_number_car || ''}
								onChange={props.onChange}
							/>
							<InputNormal
								md={6}
								label={`Número de Vin`}
								name={`vin_number_car`}
								value={props.data.vin_number_car || ''}
								onChange={props.onChange}
							/>
							<UploadNormal
								label={'Foto de Tablero'}
								fileList={props.data.board_photo_car || []}
								loading={props.state.loading.board_photo_car}
								onPreview={props.onPreview}
								onRemove={(e) => props.onRemove(e, 'car', 'board_photo_car')}
								onChange={(e) => props.onImage(e, 'car', 'board_photo_car')}
							/>
							<UploadNormal
								label={'Foto de Placa'}
								fileList={props.data.plate_photo_car || []}
								loading={props.state.loading.plate_photo_car}
								onPreview={props.onPreview}
								onRemove={(e) => props.onRemove(e, 'car', 'plate_photo_car')}
								onChange={(e) => props.onImage(e, 'car', 'plate_photo_car')}
							/>
							<UploadNormal
								label={'Foto de Costado'}
								fileList={props.data.photo_costado_car || []}
								loading={props.state.loading.photo_costado_car}
								onPreview={props.onPreview}
								onRemove={(e) => props.onRemove(e, 'car', 'photo_costado_car')}
								onChange={(e) => props.onImage(e, 'car', 'photo_costado_car')}
							/>

							<Modal
								visible={props.state.previewImage.length ? true : false}
								footer={null}
								onCancel={props.onHiddenPreview}
							>
								<img alt="picture" style={{ width: '100%' }} src={props.state.previewImage} />
							</Modal>
						</Row>
					</Col>
				</Row>
			</Col>
			<HeaderSection
				title="Revision Inicial"
				description="Marca con una X si la unidad presenta una falla en el punto seleccionado."
			/>

			<Col xs={12}>
				<Row>
					<Col xs={12}>
						<Row>
							<Col xs={12} className="p-none">
								<Row>
									{props.reviewsAvailables.map((item, key) => {
										const currentDocument = getDocumentsAvailables(
											'initial_review',
											props,
											item.type.toLowerCase()
										);
										const isExistDocument =
											currentDocument !== undefined && currentDocument.length > 0;

										return (
											<ItemCardUploadDocument
												key={key}
												currentItem={item.type.toLowerCase()}
												onClick={(e) => {
													setTypeDocument({
														type: 'initial_review',
														item: { ...item, index: key },
													});
													props.onShowUploadImage(e);
												}}
												currentDocument={currentDocument}
												onActionCard={props.onActionCard}
												onChangeSwitch={(e) => props.onChangeSwitch(e, key, 'initial_review')}
												isChecked={item.isAvailable}
												isExistDocument={isExistDocument}
												typeItem="initial_review"
											/>
										);
									})}
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<HeaderSection
				title="Pruebas de Instalacion"
				description="Marca la casilla si se realizo la prueba correpondiente y adjunta la imagen correspondiente."
			/>
			<Col xs={12}>
				<Row>
					<Col xs={12}>
						<Row>
							<Col xs={12} className="p-none">
								<Row>
									{props.installationTests.map((item, key) => {
										const currentDocument = getDocumentsAvailables(
											'installation_tests',
											props,
											item.type.toLowerCase()
										);
										const isExistDocument =
											currentDocument !== undefined && currentDocument.length > 0;

										return (
											<ItemCardUploadDocument
												key={key}
												currentItem={item.type.toLowerCase()}
												onClick={(e) => {
													setTypeDocument({
														type: 'installation_tests',
														item: { ...item, index: key },
													});
													props.onShowUploadImage(e);
												}}
												currentDocument={currentDocument}
												onActionCard={props.onActionCard}
												isExistDocument={isExistDocument}
												isChecked={item.isAvailable}
												onChangeSwitch={(e) =>
													props.onChangeSwitch(e, key, 'installation_tests')
												}
												typeItem="installation_tests"
											/>
										);
									})}
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>

			<Col xs={12}>
				<Divider />
				<Row>
					<InputNormal
						md={12}
						label={`Comentarios sobre instalacion del dispositivo`}
						name={`comments`}
						type="TextArea"
						value={props.data.comments || ''}
						onChange={props.onChange}
					/>
					<InputNormal
						md={12}
						label={`Explicaciones adicionales`}
						name={`explanation`}
						type="TextArea"
						value={props.data.explanation || ''}
						onChange={props.onChange}
					/>
				</Row>
				<Divider />
			</Col>
			<HeaderSection
				title="Liberacion de la Unidad"
				description="Fotografia de la unidad una ves finalizada la instalacion."
			/>
			<Col xs={12}>
				<Row start="xs">
					<UploadNormal
						md={6}
						label={'Tablero'}
						fileList={props.data.board_release || []}
						loading={props.state.loading.board_release}
						onPreview={props.onPreview}
						onRemove={(e) => props.onRemove(e, 'release', 'board_release')}
						onChange={(e) => props.onImage(e, 'release', 'board_release')}
					/>
					<UploadNormal
						md={6}
						label={'Area de Trabajo'}
						fileList={props.data.work_area_release || []}
						loading={props.state.loading.work_area_release}
						onPreview={props.onPreview}
						onRemove={(e) => props.onRemove(e, 'release', 'work_area_release')}
						onChange={(e) => props.onImage(e, 'release', 'work_area_release')}
					/>

					<InputNormal
						md={12}
						label={`No. Folio Liberacion de unidad`}
						name={`unit_release`}
						value={props.data.unit_release || ''}
						onChange={props.onChange}
					/>
				</Row>
			</Col>

			<Modal
				title="Sube la imagen"
				visible={props.state.isActiveCheckbox}
				footer={null}
				onCancel={props.onCancelActiveCheckbox}
			>
				<UploadByDragger
					fileList={props.state.initial_review}
					loading={props.state.loading[typeDocument.type]}
					onRemove={(e) => props.onRemove(e, typeDocument.type, typeDocument.item)}
					onChange={(e) => props.onImage(e, typeDocument.type, typeDocument.item)}
				/>
			</Modal>
		</>
	);
};

const ItemCardUploadDocument = (props) => {
	// console.log(props.currentDocument);
	// console.log(props.isExistDocument);
	// console.log(props);
	return (
		<Col md={3}>
			<Row start="xs" middle="xs" style={{ margin: 0 }}>
				<Col xs={6} md={8}>
					<p style={{ fontSize: 12, margin: 0 }}>Habilitar documento</p>
				</Col>
				<Col xs={6} md={4}>
					<Row end="xs">
						<Switch checked={props.isChecked} onChange={props.onChangeSwitch} size="small" />
					</Row>
				</Col>
			</Row>
			<Row>
				<button
					onClick={() => (props.isExistDocument || props.isChecked ? props.onClick(props.currentItem) : {})}
					className={`wrapper-item-document`}
					style={{ cursor: `${!props.isChecked ? 'not-allowed' : 'pointer'}` }}
				>
					{props.isExistDocument && props.currentDocument[0].url.length > 0 ? (
						<Row>
							<Col xs={12}>
								<Card
									style={{ width: '100%' }}
									cover={<img alt="example" src={props.currentDocument[0].url} />}
									actions={[
										<Icon
											type="eye"
											key="eye"
											onClick={() =>
												props.onActionCard(props.currentItem, 'view', props.typeItem)
											}
										/>,
										<Icon
											type="delete"
											key="delete"
											onClick={() =>
												props.onActionCard(props.currentItem, 'delete', props.typeItem)
											}
										/>,
									]}
								>
									<Meta title={props.currentItem} />
								</Card>
							</Col>
						</Row>
					) : (
						<Row>
							<Col xs={12}>
								<h4 style={{ margin: 0 }}>{props.currentItem}</h4>
							</Col>
						</Row>
					)}
				</button>
			</Row>
		</Col>
	);
};

const HeaderSection = (props) => {
	return (
		<Col xs={12}>
			<Divider />
			<h2>{props.title}</h2>
			<p>{props.description}</p>
			<Divider />
		</Col>
	);
};

const UploadByDragger = (props) => {
	return (
		<Dragger
			name="file"
			accept="image/*"
			multiple={false}
			customRequest={() => {}}
			fileList={[]}
			onRemove={props.onRemove}
			onChange={props.onChange}
			disabled={props.loading}
			className="wrapper-dragger-component"
		>
			<p className="ant-upload-drag-icon">
				<Icon type="inbox" />
			</p>
			{props.loading ? (
				<Row center="xs" middle="xs" style={{ height: '100%' }}>
					<Col xs={12}>
						<Icon type="loading" style={{ fontSize: 32 }} />
					</Col>
				</Row>
			) : (
				<>
					<p className="ant-upload-text">Sube y publica una imagen para continuar.</p>
					<p className="ant-upload-hint">Solo se aceptan los formatos tipo imagen</p>
				</>
			)}
		</Dragger>
	);
};

const UploadNormal = (props) => {
	return (
		<Col md={props.md ? props.md : 4} className="form-group--child" style={{ padding: 16 }}>
			<label className="center-upload" style={{ padding: '8px 0px' }}>
				{props.label}
			</label>
			<Upload
				className="center-upload"
				accept="image/*"
				action={() => {}}
				listType="picture-card"
				fileList={props.fileList}
				onRemove={props.onRemove}
				onPreview={props.onPreview}
				onChange={props.onChange}
			>
				{props.fileList.length < 1 ? <UploadButton loading={props.loading} /> : null}
			</Upload>
		</Col>
	);
};

const UploadButton = ({ loading }) => {
	return (
		<div>
			<Icon type={loading ? 'loading' : 'plus'} />
			<div className="ant-upload-text">{loading ? 'Cargando...' : 'Subir'}</div>
		</div>
	);
};
