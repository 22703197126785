import React from 'react';
import { Modal, Alert, Icon, Button, Input } from 'antd';
import { Logo } from '../../components/Logo';
import { WrapperInput } from '../../components/WrapperInput';
import { resetPassword } from '../../config/Helpers';
import { useHistory } from 'react-router-dom';
import { Grid, Row, Col } from 'react-flexbox-grid';

export const PageResetPassword = () => {
	const [state, setstate] = React.useState({ titleError: '', messageError: '', loading: false });
	const history = useHistory();
	const handleOnSubmitForm = (e) => {
		e.preventDefault();
		const target = e.target;
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		setstate({ ...state, loading: true });
		if (target.email.value) {
			setstate({ ...state, titleError: '' });
			if (re.test(String(target.email.value).toLowerCase())) {
				setstate({ ...state, titleError: '' });
				resetPassword(target.email.value)
					.then(() => {
						setstate({ ...state, loading: false });
						handleShowModal();
					})
					.catch((err) => {
						setstate({ ...state, loading: false });
						console.log(err);
					});
			} else {
				setstate({ ...state, titleError: 'Este correo no es valido:', messageError: 'Ej: correo@empresa.com' });
			}
		} else {
			setstate({ ...state, titleError: 'Correo:', messageError: 'Ingresa tu correo para continuar' });
		}
	};

	const handleShowModal = () => {
		Modal.success({
			title: 'Correo de restablecimiento enviado con exito',
			content: 'Revisa tu correo y accede al enlace para cambiar tu contraseña',
			onOk: () => history.push('/'),
		});
	};

	const showErrorMessage = (isExistError) => {
		return isExistError ? (
			<Alert
				description={state.messageError}
				style={{ margin: '1em 0' }}
				message={state.titleError}
				type="error"
				showIcon
				closable
			/>
		) : null;
	};

	return (
		<Grid fluid className="p-none">
			<Row middle="xs" style={{ height: '100vh' }}>
				<Col md={12}>
					<Row center="xs">
						<Col xs={10} style={{ maxWidth: 640 }}>
							<Row center="xs" className="wrapper--input">
								<Col xs={12} style={{ maxWidth: 148 }}>
									<Logo />
								</Col>
							</Row>
							<Row start="xs">
								<Col xs={12}>{showErrorMessage(state.titleError.length ? true : false)}</Col>
							</Row>
							<form onSubmit={handleOnSubmitForm}>
								<InputNormal
									md={12}
									label="Correo eléctronico"
									placeholder="Ingresa tu correo"
									size="large"
									name="email"
									type="text"
								/>
								<Col xs={12} style={{ padding: '1em 0 0 0' }}>
									<Button type="primary" htmlType="submit" className="w-100" size="large">
										{state.loading ? <Icon type="loading" style={{ padding: '0 8px' }} /> : null}{' '}
										Resertear contraseña
									</Button>
								</Col>
							</form>
						</Col>
					</Row>
				</Col>
			</Row>
		</Grid>
	);
};

export const InputNormal = (props) => {
	// value={props.value}
	return (
		<WrapperInput xs={props.xs} md={props.md} label={props.label} className={props.className}>
			<Col xs={12}>
				{props.type === 'TextArea' ? (
					<Input.TextArea
						disabled={props.disabled}
						size={props.size}
						rows={4}
						name={props.name}
						placeholder={props.placeholder}
						onChange={props.onChange}
					/>
				) : (
					<Input
						disabled={props.disabled}
						size={props.size}
						type={props.type ? props.type : 'text'}
						name={props.name}
						placeholder={props.placeholder}
						onChange={props.onChange}
					/>
				)}
			</Col>
		</WrapperInput>
	);
};
