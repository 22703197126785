import firebase from 'firebase';

const firebaseConfig = {
	apiKey: "AIzaSyBp06YptZ39RruPDOqB2rHzMxlJnCMJqYU",
	authDomain: "admin-black-solutions.firebaseapp.com",
	databaseURL: "https://admin-black-solutions.firebaseio.com",
	projectId: "admin-black-solutions",
	storageBucket: "admin-black-solutions.appspot.com",
	messagingSenderId: "98852018722",
	appId: "1:98852018722:web:72a42e24a77d9e46f8487b"
};

firebase.initializeApp(firebaseConfig);

export const firebaseFirestore = firebase.firestore();
export const firebaseAuth = firebase.auth();
export const firebaseStorage = firebase.storage().ref();
export const firebaseDatabase = firebase.database();

export { firebase };
