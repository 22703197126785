import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

export const BarGrid = ({ data }) => {
	const [state, setState] = useState([]);

	useEffect(() => {
		const config = {
			labels: Object.keys(data),
			datasets: [
				{
					data: Object.values(data),
					label: 'Servicios por mes',
					borderWidth: 1,
					backgroundColor: 'rgba(255,99,132,0.2)',
					borderColor: 'rgba(255,99,132,1)',
					hoverBackgroundColor: 'rgba(255,99,132,0.4)',
					hoverBorderColor: 'rgba(255,99,132,1)',
				},
			],
		};

		setState(config);
	}, [data]);
	return (
		<div>
			<Bar data={state} width={100} height={50} options={{ maintainAspectRatio: true }} />
		</div>
	);
};
