import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { Modal } from 'antd';
import { Login } from './pages/public/Login';
import { PageResetPassword } from './pages/public/PageResetPassword';
import { Dashboard } from './pages/private/Dashboard';
import { setClient, getClient, login } from './config/Helpers';
import { BASE_URL } from './constants/Constans';
import axios from 'axios';

import 'antd/dist/antd.css';
import './styles/App.css';
import './styles/Responsive.css';

const PrivateRoute = ({ component: Component, authed, isAdmin, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) =>
				authed === true ? (
					<Component {...props} isAdmin={isAdmin} />
				) : (
					<Redirect to={{ pathname: '/', state: { from: props.location } }} />
				)
			}
		/>
	);
};

const PublicRoute = ({ component: Component, authed, currentUser, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				const { from } = props.location.state || { from: { pathname: '/admin' } };
				return authed === false ? <Component {...props} currentUser={currentUser} /> : <Redirect to={from} />;
			}}
		/>
	);
};

export const App = () => {
	const [isAuthed, setAuthed] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);

	const handleShowModal = (message) => {
		Modal.warning({ title: 'Error', content: message });
	};

	const getTypeOfUser = (data, cb) => {
		axios
			.post(`${BASE_URL}/users/login`, data)
			.then((res) => cb(res.data))
			.catch((error) => {
				cb(null);
				console.log(error);
			});
	};

	const checkIsUserExist = (form) => {
		getTypeOfUser(form, (res) => {
			if (res !== null) {
				login(form)
					.then(() => {
						window.location.pathname === '/' && setClient({ ...res.user });
						setIsAdmin(res.user.type === 'ADMINISTRADOR');
						setAuthed(true);
					})
					.catch((error) => {
						let message = '';
						switch (error.code) {
							case 'auth/invalid-email':
								message = 'Esta dirección de correo electrónico no es valida.';
								break;
							case 'auth/user-disabled':
								message = 'El correo electrónico dado ha sido deshabilitado.';
								break;
							case 'auth/user-not-found':
								message = 'Esta dirección de correo electrónico no esta registrado.';
								break;
							case 'auth/wrong-password':
								message = 'La contraseña no es válida para el correo electrónico proporcionado.';
								break;
							default:
								break;
						}
						handleShowModal(message);
					});
			} else handleShowModal('Esta cuenta no esta registrada');
		});
	};

	const handleCheckedCurrentUser = (form) => {
		const client = getClient();

		if (form !== undefined) {
			checkIsUserExist(form);
		} else {
			if (window.location.pathname === '/') {
				if (client !== null) {
					// console.log(client);

					setIsAdmin(client.type === 'ADMINISTRADOR');
					setAuthed(true);

					// getAllData('users', (users) => {
					// 	const data = users.filter((item) => {
					// 		return item._id === client._id;
					// 	});

					// 	if (data.length > 0) {
					// 		const currentUser = { ...data[0] };
					// 		setIsAdmin(currentUser.type === 'ADMINISTRADOR');
					// 		window.location.pathname === '/' && setClient({ ...currentUser, password: null });
					// 		setAuthed(true);
					// 	}
					// });
				}
			}
		}
	};

	useEffect(() => {
		handleCheckedCurrentUser();
	}, []);

	return (
		<Router>
			<Switch>
				<PublicRoute
					exact
					path="/"
					authed={isAuthed}
					component={Login}
					currentUser={handleCheckedCurrentUser}
				/>
				<Route path="/resertear-acceso" component={PageResetPassword} />
				{/* <PublicRoute path="/resertear-acceso" authed component={PageResetPassword} /> */}
				<PrivateRoute path="/admin" authed={isAuthed} component={Dashboard} isAdmin={isAdmin} />
			</Switch>
		</Router>
	);
};
