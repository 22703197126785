import React, { useState, useEffect } from 'react';
import { Calendar, Badge, Alert, Table } from 'antd';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { getAllData, getClient } from '../../config/Helpers';
import { SUPER_ROOT } from '../../constants/Constans';
import { HeaderDashboard } from '../../components/HeaderDashboard';
import moment from 'moment';
import 'moment/locale/es';

export const CalendarWorks = () => {
	const [state, setState] = useState({ value: moment('2017-01-25'), selectedValue: moment('2017-01-25') });
	const [services, setServices] = useState([]);
	const [currentService, setCurrentService] = useState([]);

	const getListData = (value) => {
		// console.log(moment(value).format('YYYY-MM-DD'));
		let listData;
		listData = services[moment(value).format('YYYY-MM-DD')];

		// console.log(listData);
		return listData || [];
	};

	const dateCellRender = (value) => {
		const listData = getListData(value);
		return (
			<ul className="events" style={{ margin: 0, padding: 0, listStyle: 'none' }}>
				{listData.map((item, key) => {
					let status_service;
					switch (item.status_task) {
						case 'PENDING':
							status_service = 'processing';
							break;
						case 'ERROR':
							status_service = 'error';
							break;
						case 'SUCCESS':
							status_service = 'success';
							break;
						default:
							status_service = 'default';
							break;
					}

					return (
						<li key={key}>
							<Badge status={status_service} text={item.service_type} />
						</li>
					);
				})}
			</ul>
		);
	};

	const getMonthData = (value) => {
		if (value.month() === 8) {
			return 1394;
		}
	};

	const monthCellRender = (value) => {
		const num = getMonthData(value);
		return num ? (
			<div className="notes-month">
				<section>{num}</section>
				<span>Backlog number</span>
			</div>
		) : null;
	};

	const handleTableChange = (value) => {
		console.log(value);
	};

	const onSelect = (value) => {
		const listData = services[moment(value).format('YYYY-MM-DD')];
		// console.log(listData);
		listData !== undefined ? setCurrentService(listData) : setCurrentService([]);
		setState({ value, selectedValue: value });
	};

	const onPanelChange = (value) => {
		setState({ ...state, value });
	};

	useEffect(() => {
		// const client = getClient();
		// const isAdmin = client.type === 'ADMINISTRADOR';
		const isSuperAdmin = getClient() ? getClient()._id === SUPER_ROOT : false;

		getClient() &&
			getAllData('service', (data) => {
				// const servicesThisTechnician = isAdmin
				// 	? data
				// 	: data.filter((item) => item.installation_technician === client._id);

				const servicesThisTechnician = data.filter(
					(item) => {
						return !isSuperAdmin
							? (getClient().technicians || []).indexOf(item.installation_technician) !== -1
							: item;
					},
					[data]
				);

				// console.log(servicesThisTechnician);

				const services = servicesThisTechnician.reduce((acc, el) => {
					const isExistDate = acc[el.service_date] !== undefined;
					if (isExistDate) {
						return {
							...acc,
							[el.service_date.toString()]: [].concat(el, acc[el.service_date]),
						};
					} else {
						return {
							...acc,
							[el.service_date.toString()]: [].concat(el),
						};
					}
				}, {});
				setServices(services);
			});
	}, []);

	const columns = [
		{
			title: 'Orden',
			dataIndex: 'order',
			// fixed: 'left',
			width: 160,
		},
		{
			title: 'Dirección',
			dataIndex: 'address',
			render: (value, data) => data.address.maps.direction.toUpperCase(),
		},
		{
			title: 'Fecha',
			dataIndex: 'service_date',
		},
		{
			title: 'Hora',
			dataIndex: 'service_hour',
		},
		{
			title: 'Tipo de servicio',
			dataIndex: 'service_type',
			render: (value, data) => data.service_type.toUpperCase(),
			filters: [
				{ text: 'Revisión', value: 'revision' },
				{ text: 'Instalación', value: 'instalacion' },
				{ text: 'Desinstalación', value: 'desinstalacion' },
			],
			onFilter: (value, record) => record.service_type.includes(value),
		},
		{
			title: 'Prioridad',
			dataIndex: 'priority',
			render: (value, data) => data.priority.toUpperCase(),
			filters: [
				{ text: 'Baja', value: 'baja' },
				{ text: 'Media', value: 'media' },
				{ text: 'Alta', value: 'alta' },
			],
			onFilter: (value, record) => record.priority.includes(value),
		},
		{
			title: 'Instalador',
			dataIndex: 'installation_technician',
			render: (value, record) => record.installation_technician_meta_data.name.toUpperCase(),
		},
		{
			title: 'Estado',
			dataIndex: 'status_task',
			render: (value, data) => (data.status_task === 'PENDING' ? 'PENDIENTE' : 'COMPLETADO'),
			filters: [
				{ text: 'Pendiente', value: 'PENDING' },
				{ text: 'Completado', value: 'COMPLETE' },
			],
			onFilter: (value, record) => record.status_task.toString().includes(value),
		},
		{
			title: 'Cliente',
			dataIndex: 'client',
			render: (value, record) => record.client.name.toUpperCase(),
		},
		// {
		// 	index: 'action',
		// 	render: (value, record) => (
		// 		<Row center="xs" middle="xs">
		// 			<Col xs={12}>
		// 				<Button
		// 					type="primary"
		// 					shape="round"
		// 					icon="edit"
		// 					size={'large'}
		// 					style={{ marginRight: 8 }}
		// 					onClick={() => this.handleActionCurrentService(record.order, 'edit')}
		// 				/>
		// 				<Popconfirm
		// 					title="¿Estas seguro de eliminar este servicio?"
		// 					onConfirm={() => this.handleActionCurrentService(record.order, 'delete')}
		// 					onCancel={() => {}}
		// 					okText="Si, Eliminar"
		// 					cancelText="Cancelar"
		// 				>
		// 					<Button
		// 						type="danger"
		// 						shape="round"
		// 						icon="delete"
		// 						size={'large'}
		// 						style={{ marginLeft: 8 }}
		// 					/>
		// 				</Popconfirm>
		// 			</Col>
		// 		</Row>
		// 	),
		// },
	];

	return (
		<Grid fluid>
			<Row>
				<Col xs={12}>
					<HeaderDashboard title="Calendario" />
					<Row className="pt-1">
						<Col xs={12}>
							<Alert
								message={`Seleccionaste la fecha: ${
									state.selectedValue && state.selectedValue.format('YYYY-MM-DD')
								}`}
							/>
						</Col>
					</Row>

					<Calendar
						className="wrapper-calendar"
						dateCellRender={dateCellRender}
						monthCellRender={monthCellRender}
						onSelect={onSelect}
						onPanelChange={onPanelChange}
					/>
				</Col>
				<Col xs={12}>
					<Row className="pt-1">
						<Col xs={12}>
							<Table
								bordered
								columns={columns}
								rowKey={(record) => record.order}
								scroll={{ x: 'calc(880px + 100%)', y: 800 }}
								dataSource={currentService}
								pagination={{ pageSize: 10 }}
								onChange={handleTableChange}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</Grid>
	);
};
