import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { HeaderDashboard } from '../../components/HeaderDashboard';
import { FormAddService } from '../../components/FormAddService';
import { AddClientToSystem } from '../../components/AddClientToSystem';
import { VehicleInformationForm } from '../../components/VehicleInformationForm';
import { OptionNormal } from '../../components/OptionNormal';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
	BASE_URL,
	SUPER_ROOT,
	STATUS_PENDING_WORKER,
	WORKER_ACCEPTED,
	WORKER_ARRIVED,
	WORKER_IN_PROGRESS,
	WORKER_REJECTED,
	WORKER_DONE,
} from '../../constants/Constans';
import { uuidv4, uploadFile, getAllData, saveNewService, getCurrentService, getClient } from '../../config/Helpers';
import { notification, Divider, Button, Modal, message, Icon } from 'antd';
// import { DB_CLIENT } from '../../data/dummyClient';

const service_type = ['revision', 'instalacion', 'desinstalacion'];

const all_status_services = [
	{ name: 'Pendiente', _id: STATUS_PENDING_WORKER },
	{ name: 'Aceptado', _id: WORKER_ACCEPTED },
	{ name: 'Hacia el destino', _id: WORKER_ARRIVED },
	{ name: 'En progreso', _id: WORKER_IN_PROGRESS },
	{ name: 'Completado', _id: WORKER_DONE },
	// { name: 'Rechazado', _id: WORKER_REJECTED },
];

const dataInitialReview = [
	{ type: 'radio/cd', url: '', isAvailable: false },
	{ type: 'encendedor', url: '', isAvailable: false },
	{ type: 'tablero', url: '', isAvailable: false },
	{ type: 'direccionales', url: '', isAvailable: false },
	{ type: 'limpiadores', url: '', isAvailable: false },
	{ type: 'luces exteriores altas', url: '', isAvailable: false },
	{ type: 'luces exteriores bajas', url: '', isAvailable: false },
	{ type: 'luces interiores', url: '', isAvailable: false },
	{ type: 'luz freno/stop', url: '', isAvailable: false },
	{ type: 'intermitentes', url: '', isAvailable: false },
	{ type: 'botones', url: '', isAvailable: false },
	{ type: 'elevadores de ventana', url: '', isAvailable: false },
	{ type: 'retrovisores', url: '', isAvailable: false },
	{ type: 'panel de fusibles', url: '', isAvailable: false },
	{ type: 'medallon', url: '', isAvailable: false },
	{ type: 'volante', url: '', isAvailable: false },
	{ type: 'palanca de vel', url: '', isAvailable: false },
	{ type: 'vestiduras', url: '', isAvailable: false },
];

const dataInstallationTests = [
	{ type: 'toma de corriente', url: '', isAvailable: false },
	{ type: 'toma de tierra', url: '', isAvailable: false },
	{ type: 'paro de motor', url: '', isAvailable: false },
	{ type: 'ubicacion del equipo', url: '', isAvailable: false },
	{ type: 'boton de panico', url: '', isAvailable: false },
	{ type: 'foto tablero', url: '', isAvailable: false },
	{ type: 'antena gps', url: '', isAvailable: false },
	{ type: 'antena gsm', url: '', isAvailable: false },
	{ type: 'empalmes', url: '', isAvailable: false },
];

export class CurrentClientInTheSystem extends Component {
	state = {
		isActiveCheckbox: false,
		type_image: '',
		clients: [],
		admins: [],
		visible: false,
		upload: false,
		installation_technician: [],
		initial_review: dataInitialReview,
		initialReview: [],
		reviewsAvailables: [],
		installationTests: [],
		installation_tests: dataInstallationTests,
		currentClient: {},
		currentItemCard: {},
		loading: {
			board_photo_car: false,
			plate_photo_car: false,
			photo_costado_car: false,
		},
		isUpdateData: false,
		itemCard: {},
		_itemCard: {},
		type: 'service',
		type_modal: '',
		messageError: '',
		previewImage: '',
		service: {
			order: '',
			default_branch_office: '',
			priority: '',
			client: '',
			address: {
				maps: {
					direction: '',
					location: '',
				},
			},
			brand_car: '',
			model_car: '',
			sub_model_car: '',
			unit_release: '',
			year_car: '',
			kilometres_car: '',
			comments: '',
			explanation: '',
			engine_hours_car: '',
			energy_car: '',
			traction_car: '',
			economic_number_car: '',
			plate_number_car: '',
			vin_number_car: '',
			board_photo_car: [],
			plate_photo_car: [],
			photo_costado_car: [],
			board_release: [],
			work_area_release: [],
			initial_review: dataInitialReview,
			installation_tests: dataInstallationTests,
			service_type: '',
			service_date: '',
			service_hour: '',
			installation_technician: '',
			status_task: STATUS_PENDING_WORKER,
		},
		_service: {
			order: '',
			default_branch_office: '',
			priority: '',
			client: '',
			address: {
				maps: {
					direction: '',
					location: '',
				},
			},
			brand_car: '',
			model_car: '',
			unit_release: '',
			sub_model_car: '',
			year_car: '',
			kilometres_car: '',
			engine_hours_car: '',
			energy_car: '',
			traction_car: '',
			comments: '',
			explanation: '',
			economic_number_car: '',
			plate_number_car: '',
			vin_number_car: '',
			board_photo_car: [],
			plate_photo_car: [],
			photo_costado_car: [],
			initial_review: [],
			board_release: [],
			work_area_release: [],
			installation_tests: [],
			service_type: '',
			service_date: '',
			service_hour: '',
			installation_technician: '',
			status_task: STATUS_PENDING_WORKER,
		},
	};

	handleShowNotification = (type, title) => {
		notification[type]({
			message: title,
			description: `Este cambio ya se ha aplicado en el sistema`,
		});
	};

	handleInputChange = (event) => {
		const { name, value } = event.target;

		// console.log(name);
		// console.log(value);

		if (name === 'discount' || name === 'price' || name === 'stock') {
			if (value.length <= 10) {
				if (!isNaN(value)) {
					this.setState({ [this.state.type]: { ...this.state[this.state.type], [name]: parseInt(value) } });
				}
			}
			if (!value.length) {
				this.setState({ [this.state.type]: { ...this.state[this.state.type], [name]: '' } });
			}
		} else {
			this.setState({ [this.state.type]: { ...this.state[this.state.type], [name]: value } });
		}
	};

	handleSelect = (value, type) => {
		if (type === 'client') {
			const currentClient = this.state.clients.filter((item) => item._id === value)[0];
			// console.log(currentClient);
			this.setState({
				currentClient,
				[this.state.type]: { ...this.state[this.state.type], [type]: value },
			});
		}

		if (type === 'installation_technician') {
			const data = this.state.installation_technician.filter((item) => item._id === value);
			if (data.length > 0) {
				const installation_technician = { ...data[0] };
				this.setState({
					[this.state.type]: {
						...this.state[this.state.type],
						[type]: installation_technician._id,
						technician: installation_technician._id,
					},
				});
			}
		}

		this.setState({ [this.state.type]: { ...this.state[this.state.type], [type]: value } });
	};

	handleTableChange = (value) => {
		console.log(value);
	};

	handleOnChangeDate = (date, service_date, type) => {
		// console.log(date, service_date);
		this.setState({ [this.state.type]: { ...this.state[this.state.type], [type]: service_date } });
	};

	handleOnChangeTime = (time, service_hour, type) => {
		// console.log(time, service_hour);
		this.setState({ [this.state.type]: { ...this.state[this.state.type], [type]: service_hour } });
	};

	handleOnPlaceSelected = (place) => {
		if (place.formatted_address !== undefined) {
			this.setState({
				[this.state.type]: {
					...this.state[this.state.type],
					address: {
						...this.state[this.state.type].address,
						maps: {
							direction: place.formatted_address,
							location: {
								lat: place.geometry.location.lat(),
								lon: place.geometry.location.lng(),
							},
						},
					},
				},
			});
		}
	};

	handleOnSelectLocaties = (value) => {
		this.setState({ [this.state.type]: { ...this.state[this.state.type], ['client']: value } });
	};

	handleOnPreviewPicture = (e) => {
		this.setState({ previewImage: e.url });
	};

	handleOnRemovePicture = (e, type, name) => {
		let pictures = this.state[this.state.type][name].filter((item) => item.uid !== e.uid);
		this.state[this.state.type][name] = pictures;
		this.setState({ ...this.state });
	};

	handleOnActionCard = (id_document, type, type_document) => {
		// console.log(type_document);
		// console.log(type);

		switch (type) {
			case 'delete':
				const documentsAvailables = this.state[this.state.type][type_document].filter((item) => {
					return item.type !== id_document;
				});
				this.setState({
					[this.state.type]: {
						...this.state[this.state.type],
						[type_document]: documentsAvailables,
					},
				});
				// console.log(documentsAvailables);
				break;

			default:
				const currentDocument = this.state[this.state.type][type_document].filter((item) => {
					return item.type === id_document;
				});
				this.setState({ previewImage: currentDocument[0].url });
				// console.log(currentDocument);
				break;
		}
	};

	handleChangeImage = (info, node, name) => {
		if (info.file.status === 'uploading') {
			if (node === 'initial_review' || node === 'installation_tests') {
				// console.log(node);
				this.setState({ loading: { ...this.state.loading, [node]: true } });

				const { isAvailable, type, url, index } = name;
				// console.log(isAvailable);
				// console.log(type);
				// console.log(url);
				// console.log(index);
				uploadFile(
					info.file,
					`documents/${uuidv4()}`,
					(url) => {
						const picturesNew = {
							isAvailable,
							url: url,
							type: this.state.type_image,
						};

						// const data = JSON.parse(JSON.stringify(this.state[this.state.type][node]));
						// console.log(picturesNew);
						// console.log(data);

						// this.state[this.state.type][node][index] = { ...this.state[this.state.type][node][index], isAvailable };
						this.state[this.state.type][node][index] = picturesNew;
						// this.setState({ ...this.state });

						// this.setState({
						// 	[this.state.type]: {
						// 		...this.state[this.state.type],
						// 		[node]: data,
						// 	},
						// 	type_image: '',
						// });
						this.setState({ type_image: '' });
					},
					(percetage) => {
						if (percetage === 100) {
							this.setState({
								loading: { ...this.state.loading, [node]: false },
								isActiveCheckbox: false,
							});

							message.success('Imagen cargada con exito');
						}
					}
				);

				return;
			} else {
				this.setState({ loading: { ...this.state.loading, [name]: true } });
				uploadFile(
					info.file,
					`documents/${uuidv4()}`,
					(url) => {
						const picturesNew = {
							uid: `ykn${Math.round(Math.random() * 1000)}mln${Math.round(Math.random() * 1000)}`,
							status: 'done',
							url: url,
						};

						this.state[this.state.type][name] = [...this.state[this.state.type][name], { ...picturesNew }];
						this.setState({ ...this.state });
					},
					(percetage) => {
						if (percetage === 100) {
							this.setState({ loading: { ...this.state.loading, [name]: false } });
						}
					}
				);

				return;
			}
		}
	};

	handleShowUploadImage = (value) => {
		// console.log(value);
		// console.log(value[value.length - 1]);
		this.setState({ isActiveCheckbox: true, type_image: value });
	};

	handleAddService = (data) => {
		this.setState({ upload: true });
		const status_task =
			data.installation_technician_meta_data.type_employee === 'EMPLEADO'
				? WORKER_ACCEPTED
				: STATUS_PENDING_WORKER;
		// console.log(status_task);
		// console.log(data);
		saveNewService(data.order, 'new', { ...data, status_task }, (existService) => {
			if (existService) {
				// Modal.confirm({
				// 	title: 'Ya existe este número de orden',
				// 	content: '¿Desea continuar con el registro del servicio?',
				// 	onOk: () => {},
				// 	onCancel: () => {
				// 		// setTimeout(() => window.location.reload(), 100)
				// 		return this.props.history.push({ pathname: '/' });
				// 	},
				// 	okText: 'Si, continuar',
				// 	cancelText: 'Cancelar',
				// });
				this.setState({ upload: false });
			} else {
				setTimeout(() => {
					this.setState({
						upload: false,
						visible: false,
						service: this.state._service,
					});
					let message =
						this.state.type === 'itemCard'
							? 'Servicio actualizado correctamente'
							: 'El servicio se agrego con exito';
					this.handleShowNotification('success', message);
					// setTimeout(() => window.location.reload(), 100);
					setTimeout(() => this.props.history.push({ pathname: '/admin/servicios' }), 100);
				}, 200);
			}
		});
	};

	handleUpdateService = (data) => {
		this.setState({ upload: true });
		saveNewService(data.order, 'update', data, () => {
			this.setState({
				upload: false,
				visible: false,
				service: this.state._service,
			});
			let message = 'Servicio actualizado correctamente';
			this.handleShowNotification('success', message);

			// setTimeout(() => window.location.reload(), 100);
			setTimeout(() => this.props.history.push({ pathname: '/admin/servicios' }), 100);
		});
	};

	handleToAssignService = (data, cb) => {
		axios
			.post(`${BASE_URL}/service/assign`, data)
			.then(() => cb())
			.catch((err) => {
				console.log(err);
				this.showErrorMessageModal('No se logro crear este servicio');
			});
	};

	showErrorMessageModal = (content) => {
		return Modal.error({
			title: 'Hubo un error',
			content,
		});
	};

	handleOnOk = () => {
		if (this.state[this.state.type].order.length) {
			if (this.state[this.state.type].priority.length) {
				if (this.state[this.state.type].client.length) {
					if (this.state[this.state.type].default_branch_office !== '') {
						if (this.state[this.state.type].service_type.length) {
							if (this.state[this.state.type].service_date.length) {
								if (this.state[this.state.type].service_hour.length) {
									if (this.state[this.state.type].installation_technician.length) {
										// console.log('guardar');
										this.setState({ messageError: '' });

										const refState = this.state[this.state.type];

										const board_photo_car =
											refState.board_photo_car !== null &&
											refState.board_photo_car !== undefined &&
											refState.board_photo_car.length
												? refState.board_photo_car[0].url
												: null;
										const plate_photo_car =
											refState.plate_photo_car !== null &&
											refState.plate_photo_car !== undefined &&
											refState.plate_photo_car.length
												? refState.plate_photo_car[0].url
												: null;
										const photo_costado_car =
											refState.photo_costado_car !== null &&
											refState.photo_costado_car !== undefined &&
											refState.photo_costado_car.length
												? refState.photo_costado_car[0].url
												: null;
										const board_release =
											refState.board_release !== null &&
											refState.board_release !== undefined &&
											refState.board_release.length
												? refState.board_release[0].url
												: null;
										const work_area_release =
											refState.work_area_release !== null &&
											refState.work_area_release !== undefined &&
											refState.work_area_release.length
												? refState.work_area_release[0].url
												: null;

										const installation_tests =
											refState.installation_tests !== null &&
											refState.installation_tests !== undefined &&
											refState.installation_tests.length
												? refState.installation_tests.filter((el) => el.isAvailable)
												: null;
										const initial_review =
											refState.initial_review !== null &&
											refState.initial_review !== undefined &&
											refState.initial_review.length
												? refState.initial_review.filter((el) => el.isAvailable)
												: null;

										const client = this.state.clients.filter(
											(item) => item._id === refState.client
										)[0];

										const installation_technician = refState.installation_technician;

										const installation_technician_meta_data =
											this.state.installation_technician.filter(
												(item) => item._id === refState.installation_technician
											)[0] || {};

										const isExistCurrentClient =
											Object.getOwnPropertyNames(this.state.currentClient).length === 0;
										if (!isExistCurrentClient) {
											if (this.state.currentClient.inputList.length) {
												const currentInputList =
													this.state.currentClient.inputList[refState.default_branch_office];

												// console.log(client);
												// console.log(this.state.currentClient);

												const data = {
													...refState,
													...currentInputList,
													client,
													installation_technician,
													installation_technician_meta_data,
													board_photo_car,
													plate_photo_car,
													photo_costado_car,
													board_release,
													work_area_release,
													installation_tests,
													initial_review,
												};

												// console.log(data);

												// this.handleToAssignService(data, () => {
												this.state.type === 'itemCard'
													? this.handleUpdateService(data)
													: this.handleAddService(data);
												// });
											}
										}
									} else {
										this.setState({
											messageError: 'Selecciona un técnico para realizar el servicio',
										});
									}
								} else {
									this.setState({ messageError: 'Selecciona la hora del servicio' });
								}
							} else {
								this.setState({ messageError: 'Selecciona la fecha del servicio' });
							}
						} else {
							this.setState({ messageError: 'Selecciona el tipo de servicio' });
						}
					} else {
						this.setState({ messageError: 'Ingresa la dirección del servicio' });
					}
				} else {
					this.setState({ messageError: 'Selecciona el cliente a realizar el servicio' });
				}
			} else {
				this.setState({ messageError: 'Selecciona la prioridad del servicio' });
			}
		} else {
			this.setState({ messageError: 'Ingresa el número de orden para el servicio' });
		}
	};

	handleOnHiddenPreview = () => {
		this.setState({ previewImage: '' });
	};

	componentWillMount() {
		// console.log(this.props.location.type);
		if (this.props.location.type !== undefined) {
			this.setState({ type: this.props.location.type });
		}
	}

	componentDidMount() {
		const isSuperAdmin = getClient()?.isSuperRoot;

		getAllData('service', (services) => this.setState({ data: services }));

		getAllData('users', (data) => {
			const admins = data.filter((item) => item.type === 'ADMINISTRADOR');
			const installation_technician = data.filter((item) => item.type === 'TECNICO');
			this.setState({ installation_technician, admins });
		});

		getAllData('clients', (clients) => {
			if (this.props.location.type !== undefined) {
				if (this.props.location.type === 'itemCard') {
					const { params } = this.props.match;

					getCurrentService(params.id, (data) => {
						const board_photo_car =
							data.board_photo_car !== null &&
							data.board_photo_car !== undefined &&
							data.board_photo_car.length
								? [
										{
											uid: `ykn${Math.round(Math.random() * 1000)}mln${Math.round(
												Math.random() * 1000
											)}`,
											status: 'done',
											url: data.board_photo_car,
										},
								  ]
								: [];

						const plate_photo_car =
							data.plate_photo_car !== null &&
							data.plate_photo_car !== undefined &&
							data.plate_photo_car.length
								? [
										{
											uid: `ykn${Math.round(Math.random() * 1000)}mln${Math.round(
												Math.random() * 1000
											)}`,
											status: 'done',
											url: data.plate_photo_car,
										},
								  ]
								: [];

						const photo_costado_car =
							data.photo_costado_car !== null &&
							data.photo_costado_car !== undefined &&
							data.photo_costado_car.length
								? [
										{
											uid: `ykn${Math.round(Math.random() * 1000)}mln${Math.round(
												Math.random() * 1000
											)}`,
											status: 'done',
											url: data.photo_costado_car,
										},
								  ]
								: [];

						const board_release =
							data.board_release !== null && data.board_release !== undefined && data.board_release.length
								? [
										{
											uid: `ykn${Math.round(Math.random() * 1000)}mln${Math.round(
												Math.random() * 1000
											)}`,
											status: 'done',
											url: data.board_release,
										},
								  ]
								: [];

						const work_area_release =
							data.work_area_release !== null &&
							data.work_area_release !== undefined &&
							data.work_area_release.length
								? [
										{
											uid: `ykn${Math.round(Math.random() * 1000)}mln${Math.round(
												Math.random() * 1000
											)}`,
											status: 'done',
											url: data.work_area_release,
										},
								  ]
								: [];

						const pre_initial_review =
							data.initial_review !== null &&
							data.initial_review !== undefined &&
							data.initial_review.length
								? data.initial_review
								: [];

						const initial_review = [];
						const clone_dataInitialReview = [...dataInitialReview];
						pre_initial_review.forEach((item) => {
							return clone_dataInitialReview.forEach((el) => {
								if (el.type === item.type) {
									el.url = item.url;
									el.isAvailable = item.isAvailable;
								}
							});
						});
						// dataInitialReview

						const pre_installation_tests =
							data.installation_tests !== null &&
							data.installation_tests !== undefined &&
							data.installation_tests.length
								? data.installation_tests
								: [];

						const installation_tests = [];
						const clone_dataInstallationTests = [...dataInstallationTests];
						pre_installation_tests.forEach((item) => {
							return clone_dataInstallationTests.forEach((el) => {
								if (el.type === item.type) {
									el.url = item.url;
									el.isAvailable = item.isAvailable;
								}
							});
						});

						const client = data.client._id !== undefined ? data.client._id : '';
						const installation_technician = data.installation_technician || '';
						const installation_technician_meta_data = data.installation_technician_meta_data || null;

						const currentClient = clients.filter((item) => item._id === client)[0];

						const itemCard = {
							...data,
							client,
							installation_technician,
							installation_technician_meta_data,
							board_photo_car,
							plate_photo_car,
							work_area_release,
							photo_costado_car,
							board_release,
							work_area_release,
							installation_tests: clone_dataInstallationTests,
							initial_review: clone_dataInitialReview,
						};

						this.setState({
							itemCard,
							currentClient,
							_itemCard: itemCard,
							initialReview: clone_dataInitialReview,
							reviewsAvailables: clone_dataInitialReview,
							installationTests: clone_dataInstallationTests,
							isUpdateData: this.props.location.type.length ? true : false,
						});
					});
				} else {
					this.setState({
						initialReview: dataInitialReview,
						reviewsAvailables: dataInitialReview,
						installationTests: dataInstallationTests,
					});
				}
			}

			const filterClientsAvailables = clients.filter(
				(item) => {
					return !isSuperAdmin ? (getClient().clients || []).indexOf(item._id) !== -1 : item;
				},
				[clients]
			);

			this.setState({ clients: filterClientsAvailables });
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevState[this.state.type].initial_review !== undefined &&
			prevState[this.state.type].initial_review.length !== this.state[this.state.type].initial_review.length
		) {
			// const data = this.state[this.state.type].initial_review.map((item) => item.type);
			// console.log(data);
			this.setState({
				// initial_review: data,
				type_image: '',
			});
		}
		if (
			prevState[this.state.type].installation_tests !== undefined &&
			prevState[this.state.type].installation_tests.length !==
				this.state[this.state.type].installation_tests.length
		) {
			// const data = this.state[this.state.type].installation_tests.map((item) => item.type);
			// console.log(data);
			this.setState({
				// installation_tests: data,
				type_image: '',
			});
		}
	}

	showModal = () => {
		this.setState({ visible: true, type_modal: 'client', currentItemCard: {} });
	};

	hiddenModal = () => {
		this.setState({ visible: false, currentItemCard: {} });
	};

	handleAddClient = (data, _id) => {
		const clients = this.state.clients;
		clients.push({ ...data, _id });
		this.setState({ clients });
	};

	handleUpdateClient = (data) => {
		const clients = this.state.clients.filter((item) => item._id !== data._id);
		clients.push({ ...data });
		this.setState({
			clients,
			[this.state.type]: { ...this.state[this.state.type], ['client']: '', ['default_branch_office']: '' },
		});
	};

	handleOnUpdate = () => {
		this.setState({ currentItemCard: this.state.currentClient, type_modal: 'itemCard', visible: true });
	};

	getCurrentStatus = (status = '') => {
		let currentStatus;
		switch (status) {
			case STATUS_PENDING_WORKER:
				currentStatus = 'Pendiente';
				break;
			case WORKER_ACCEPTED:
				currentStatus = 'Aceptado';
				break;
			case WORKER_ARRIVED:
				currentStatus = 'Hacia el destino';
				break;
			case WORKER_IN_PROGRESS:
				currentStatus = 'En progreso';
				break;
			case WORKER_REJECTED:
				currentStatus = 'Rechazado';
				break;
			case WORKER_DONE:
				currentStatus = 'Completado';
				break;
			default:
				currentStatus = '---';
				break;
		}

		return currentStatus;
	};

	handleOnChangeSwitch = (isAvailable, index, type) => {
		this.state[this.state.type][type][index].isAvailable = isAvailable;
		this.setState({ ...this.state });
	};

	render() {
		console.log(this.state);
		if (this.state[this.state.type] === undefined || !this.state.type.length) {
			return <PageLoadData title="No hay datos que mostrar" isLink typeIcon="loading" />;
		}

		if (Object.entries(this.state[this.state.type]).length === 0 || Array.isArray(this.state[this.state.type])) {
			return <PageLoadData title="cargando datos del cliente..." typeIcon="loading" />;
		}
		return (
			<>
				<Grid fluid>
					<AddClientToSystem
						admins={this.state.admins}
						visible={this.state.visible}
						itemCard={this.state.currentItemCard}
						type={this.state.type_modal}
						onHiddenModal={this.hiddenModal}
						onAddClient={this.handleAddClient}
						onUpdateClient={this.handleUpdateClient}
					/>
					<form onSubmit={(e) => e.preventDefault()} noValidate>
						<Row center="xs">
							<Col xs={12}>
								<HeaderDashboard
									title="Nuevo servicio"
									extra
									otherBtn={this.state.isUpdateData}
									onUpdate={this.handleOnUpdate}
									onClick={this.showModal}
									textBtn="AGREGAR USUARIO"
								/>
								<Row>
									<Col xs={12}>
										<Divider />
										<Row>
											<Col xs={12} style={{ padding: 0 }}>
												<OptionNormal
													md={12}
													type
													isAdmin
													data={all_status_services}
													label={`Estatus del servicio`}
													placeholder="Busca por el nombre del cliente"
													defaultValue={this.state[this.state.type].status_task || ''}
													onChange={(e) => this.handleSelect(e, 'status_task')}
												/>
											</Col>
										</Row>
										<FormAddService
											isRejectService={
												this.state[this.state.type].status_task === WORKER_REJECTED
											}
											data={this.state[this.state.type]}
											currentClient={this.state.currentClient}
											isUpdateData={this.state.isUpdateData}
											clients={this.state.clients}
											service_type={service_type}
											installation_technician={this.state.installation_technician}
											messageError={this.state.messageError}
											onChange={this.handleInputChange}
											onSelect={this.handleSelect}
											onDate={this.handleOnChangeDate}
											onTime={this.handleOnChangeTime}
											onSelectLocaties={this.handleOnSelectLocaties}
										/>
									</Col>
									<VehicleInformationForm
										data={this.state[this.state.type]}
										onSelect={this.handleSelect}
										onChange={this.handleInputChange}
										onCancelActiveCheckbox={() => this.setState({ isActiveCheckbox: false })}
										onShowUploadImage={this.handleShowUploadImage}
										onImage={this.handleChangeImage}
										onChangeSwitch={this.handleOnChangeSwitch}
										onActionCard={this.handleOnActionCard}
										onPreview={this.handleOnPreviewPicture}
										onHiddenPreview={this.handleOnHiddenPreview}
										onRemove={this.handleOnRemovePicture}
										initialReview={this.state.initialReview}
										reviewsAvailables={this.state.reviewsAvailables}
										installationTests={this.state.installationTests}
										state={this.state}
									/>
									<Col xs={12}>
										<Row center="xs">
											<Col xs={12}>
												<Button
													type="primary"
													size="large"
													disabled={this.state.upload}
													onClick={this.state.upload ? () => {} : this.handleOnOk}
													style={{
														padding: '1.5em',
														margin: '1em 0',
														width: '100%',
														lineHeight: '0',
													}}
												>
													GUARDAR
												</Button>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</form>
				</Grid>
			</>
		);
	}
}

const PageLoadData = (props) => {
	return (
		<Grid>
			<Row center="xs" middle="xs" style={{ height: '100vh' }}>
				<Col xs={12}>
					{props.typeIcon && <Icon type={props.typeIcon} />}
					<h2>{props.title}</h2>
					{props.isLink && (
						<Link to={'/admin/servicios'} className="btn-primary">
							REGRESAR A SERVICIOS
						</Link>
					)}
				</Col>
			</Row>
		</Grid>
	);
};
