import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';

// const data = {
// 	labels: ['Red', 'Blue', 'Yellow'],
// 	datasets: [
// 		{
// 			data: [300, 50, 100],
// 			backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
// 			hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
// 		},
// 	],
// };

export const PieGrid = ({ data }) => {
	const [state, setState] = useState([]);

	useEffect(() => {
		const config = {
			labels: Object.keys(data),
			datasets: [
				{
					data: Object.values(data),
					backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
					hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
				},
			],
		};

		setState(config);
	}, [data]);

	return (
		<div>
			<Pie data={state} />
		</div>
	);
};
