import React from 'react';
import { Col } from 'react-flexbox-grid';
import { Select, Icon } from 'antd';

export const OptionWithFilter = (props) => {
	return (
		<Col xs={props.xs ?? 12} md={props.md ?? 6}>
			<Select
				showSearch
				allowClear
				clearIcon={<Icon type="close-circle" theme="filled" onClick={props.onClearFilter} />}
				size="large"
				style={{ width: '100%' }}
				placeholder={props.placeholder}
				optionFilterProp="children"
				onChange={props.onChange}
				onSelect={props.onSelect}
				onFocus={props.onFocus}
				onInputKeyDown={props.onInputKeyDown}
				onBlur={props.onBlur}
				onDeselect={props.onDeselect}
				disabled={props.disabled}
				onSearch={props.onSearch}
				filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
			>
				{props.options.map((el, key) => (
					<Select.Option key={key} value={el[props.propValue]}>
						{el[props.propLabel]}
					</Select.Option>
				))}
			</Select>
		</Col>
	);
};
