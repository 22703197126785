import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { InputNormal } from './InputNormal';
import { OptionNormal } from './OptionNormal';
import { WrapperInput } from './WrapperInput';
import { Alert, TimePicker, DatePicker, Select } from 'antd';
import { DATE_SUGGESTION_WORKER, HOUR_SUGGESTION_WORKER, SUPER_ROOT } from '../constants/Constans';
import { getClient } from '../config/Helpers';
import moment from 'moment';
import 'moment/locale/es';

const { Option } = Select;

export const FormAddService = (props) => {
	let isExistCurrentClient;
	let currentAddress;
	const isSuperAdmin = getClient() ? getClient()._id === SUPER_ROOT : false;

	if (props.currentClient === undefined) {
		isExistCurrentClient = true;
	} else {
		isExistCurrentClient = Object.getOwnPropertyNames(props.currentClient).length === 0;

		if (!isExistCurrentClient) {
			if (props.currentClient.inputList.length) {
				const currentInputList = props.currentClient.inputList[props.data.default_branch_office];
				if (!Array.isArray(currentInputList) && currentInputList !== null && currentInputList !== undefined) {
					if (!(Object.getOwnPropertyNames(currentInputList).length === 0)) {
						currentAddress = currentInputList.address.maps.direction;
					}
				}
			}
		}
	}

	return (
		<>
			<Row>
				{props.messageError.length ? (
					<Alert message={props.messageError} type="error" style={{ margin: '0 0 20px', width: '100%' }} />
				) : null}
				<Col xs={12}>
					<Row>
						<InputNormal
							md={7}
							label={`Número de orden`}
							name={`order`}
							value={props.data.order || ''}
							onChange={props.onChange}
							disabled={props.isUpdateData}
						/>
						<OptionNormal
							md={5}
							data={['baja', 'media', 'alta']}
							label={`Prioridad`}
							defaultValue={props.data.priority || ''}
							onChange={(e) => props.onSelect(e, 'priority')}
						/>
						<OptionNormal
							md={12}
							type
							isAdmin
							data={props.clients}
							label={`Cliente`}
							placeholder="Busca por el nombre del cliente"
							defaultValue={props.data.client || ''}
							onChange={(e) => props.onSelect(e, 'client')}
						/>
						<WrapperInput md={6} label={'Selecciona una sucursal'}>
							<Col xs={12}>
								<Select
									showSearch
									allowClear
									placeholder="Selecciona una opción para continuar"
									disabled={isExistCurrentClient}
									value={props.data.default_branch_office}
									onChange={(e) => props.onSelect(e, 'default_branch_office')}
									className="w-100"
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
								>
									{!isExistCurrentClient &&
										props.currentClient.inputList.map((item, index) => {
											return (
												<Option key={index} value={index}>
													{item['branch_office'].toUpperCase()}
												</Option>
											);
										})}
								</Select>
							</Col>
						</WrapperInput>
						<InputNormal md={6} label={`Dirección`} value={currentAddress || ''} disabled={true} />
						<WrapperInput md={6} label={'Fecha'}>
							<Col xs={12}>
								<DatePicker
									disabled={props.isRejectService}
									onChange={(a, e) => props.onDate(a, e, 'service_date')}
									defaultValue={props.data.service_date !== '' ? moment(props.data.service_date) : ''}
									className="w-100"
								/>
							</Col>
						</WrapperInput>
						<WrapperInput md={6} label={'Hora'}>
							<Col xs={12}>
								<TimePicker
									disabled={props.isRejectService}
									onChange={(a, e) => props.onTime(a, e, 'service_hour')}
									defaultValue={
										props.data.service_hour !== ''
											? moment(props.data.service_hour, 'HH:mm:ss')
											: ''
									}
									className="w-100"
								/>
							</Col>
						</WrapperInput>
						{props.isRejectService && (
							<>
								<WrapperInput md={6} label={'Nueva fecha de servicio'}>
									<Col xs={12}>
										<DatePicker
											onChange={(a, e) => props.onDate(a, e, DATE_SUGGESTION_WORKER)}
											defaultValue={
												props.data.DATE_SUGGESTION_WORKER !== ''
													? moment(props.data.service_date)
													: ''
											}
											className="w-100"
										/>
									</Col>
								</WrapperInput>
								<WrapperInput md={6} label={'Nueva hora de servicio'}>
									<Col xs={12}>
										<TimePicker
											onChange={(a, e) => props.onTime(a, e, HOUR_SUGGESTION_WORKER)}
											defaultValue={
												props.data.service_hour !== ''
													? moment(props.data.HOUR_SUGGESTION_WORKER, 'HH:mm:ss')
													: ''
											}
											className="w-100"
										/>
									</Col>
								</WrapperInput>
							</>
						)}
						<WrapperInput md={6} label={`Selecciona un instalador`}>
							<Col xs={12}>
								<Select
									showSearch
									allowClear
									placeholder="Selecciona una opción para continuar"
									value={props.data.installation_technician || ''}
									onChange={(e) => props.onSelect(e, 'installation_technician')}
									className="w-100"
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
								>
									{props.installation_technician.map((item, index) => {
										return (
											<Option key={index} value={item['_id']}>
												{item['name'].toUpperCase()}
											</Option>
										);
									})}
								</Select>
							</Col>
						</WrapperInput>
						<OptionNormal
							md={6}
							data={props.service_type}
							label={`Selecciona un tipo de servicio`}
							defaultValue={props.data.service_type || ''}
							onChange={(e) => props.onSelect(e, 'service_type')}
						/>
					</Row>
				</Col>
			</Row>
		</>
	);
};
