import React from 'react';
import LOGO from '../images/logo.png';

export const Logo = (props) => {
	return (
		<div>
			<img
				src={LOGO}
				alt="logotipo"
				className="img-fluid"
				style={{ maxWidth: props.maxWidth ? props.maxWidth : 'auto' }}
			/>
		</div>
	);
};
