import React from 'react';
import { Col } from 'react-flexbox-grid';
import { WrapperInput } from './WrapperInput';
import { Select } from 'antd';

const { Option } = Select;

export const OptionNormal = (props) => {
	return (
		<WrapperInput xs={props.xs} md={props.md} label={props.label} className={props.className}>
			<Col xs={12}>
				<Select
					showSearch
					allowClear
					disabled={props.disabled}
					placeholder="Selecciona una opción para continuar"
					value={props.defaultValue}
					onChange={props.onChange}
					className="w-100"
					filterOption={(input, option) =>
						option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
				>
					{props.data.length &&
						props.data.map((item, index) => {
							const child = props.client ? 'client' : props.isAdmin ? '_id' : 'name';

							return props.type ? (
								<Option key={index} value={item[child]}>
									{item[props.client ? 'client' : 'name'].toUpperCase()}
								</Option>
							) : (
								<Option key={index} value={item}>
									{item.toUpperCase()}
								</Option>
							);
						})}
				</Select>
			</Col>
		</WrapperInput>
	);
};
