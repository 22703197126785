import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';

export const DoughnutGrid = ({ data }) => {
	const [state, setState] = useState([]);

	useEffect(() => {
		const config = {
			labels: ['Revisión', 'Instalación', 'Desinstalación'],
			datasets: [
				{
					data: Object.values(data),
					backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
					hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
				},
			],
		};

		setState(config);
	}, [data]);

	return (
		<div>
			{/* <h2>TOTAL DE SERVICIOS</h2> */}
			<Doughnut data={state} />
		</div>
	);
};
