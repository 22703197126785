import React, { Component } from 'react';
import { firebaseDatabase } from '../../config/Firebase';
import { API_GOOGLE_KEY } from '../../constants/Constans';
import { FilterListConductor } from '../../components/FilterListConductor';
import { ListConductorsForState } from '../../components/ListConductorsForState';
import { InfoWindow, withGoogleMap, withScriptjs, GoogleMap, Marker } from 'react-google-maps';
import { getAllData, getClient, removeServiceCurrentConductor } from '../../config/Helpers';
import { Icon, Avatar, Drawer, Typography, Badge, Modal } from 'antd';
import { Grid, Row, Col } from 'react-flexbox-grid';

const { Title, Text } = Typography;

var numDeltas = 100;
var delay = 10; //milliseconds

const Map = withScriptjs(
	withGoogleMap((props) => {
		let iconMarker = new window.google.maps.MarkerImage(
			Image,
			null /* size is determined at runtime */,
			null /* origin is 0,0 */,
			null /* anchor is bottom center of the scaled image */,
			new window.google.maps.Size(100, 100)
		);

		let markerOffline = new window.google.maps.MarkerImage(
			null,
			null /* size is determined at runtime */,
			null /* origin is 0,0 */,
			null /* anchor is bottom center of the scaled image */,
			new window.google.maps.Size(100, 100)
		);

		const bounds = new window.google.maps.LatLngBounds();

		props.conductors.map((location) => {
			if (location.last_location) {
				bounds.extend(new window.google.maps.LatLng(location.last_location.lat, location.last_location.lon));
			}
		});

		const isExistCurrentDriver =
			props.state.currentDriver && props.state.currentDriver.last_location && props.isShowMoreInfoDriver;
		// console.log(props.state);
		// console.log(isExistCurrentDriver);

		return (
			<GoogleMap
				defaultZoom={12}
				ref={(map) => {
					if (map && props.conductors.length > 0 && props.isFirstLoad) {
						props.doneShowingMap();
						map.fitBounds(bounds);
					}

					props.onMapReady(map);
				}}
				center={props.center}
				onClick={() => {}}
			>
				{props.conductors.map((conductor) => {
					return conductor.last_location ? (
						<Marker
							position={{
								lat: conductor.last_location.lat,
								lng: conductor.last_location.lon,
							}}
							icon={iconMarker}
							defaultAnimation={window.google.maps.Animation.DROP}
							onClick={() => props.onMapClick(conductor)}
						/>
					) : null;
				})}
				{isExistCurrentDriver && (
					<InfoWindow
						onCloseClick={() => props.onHiddenWindowInfoDriver()}
						position={{
							lat: props.state.currentDriver.last_location.lat,
							lng: props.state.currentDriver.last_location.lon,
						}}
					>
						<Row style={{ margin: 0 }}>
							<p
								style={{ margin: 0, padding: 8 }}
							>{`${props.state.currentDriver.installation_technician_meta_data.name}`}</p>
						</Row>
					</InfoWindow>
				)}
				{/* {props.isMarkerShown && <Marker position={props.markerPosition} icon={iconMarker} />} */}
			</GoogleMap>
		);
	})
);

const formatter = new Intl.NumberFormat('en-MX', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });
const formatNumber = new Intl.NumberFormat('en-MX', { minimumFractionDigits: 2 });

export class MapView extends Component {
	state = {
		currentsAllDrivers: [],
		typeSelect: '',
		isVisibleDrawer: false,
		isShowMoreInfoDriver: false,
		currentDriver: [],
		loading: true,
		conductors: [],
		offline: [],
		states: [],
		allTechnician: [],
		center: { lat: 19.429592, lng: -99.199975 },
		map: null,
		childAdded: null,
		isRoot: false,
		childRemove: null,
		childChanged: null,
		isFirstLoad: true,
	};

	getOnlineConductors() {
		const isSuperAdmin = getClient()?.isSuperRoot;
		let childAdded = firebaseDatabase.ref('online');
		const conductors = [];
		childAdded.on('child_added', (snapshot) => {
			console.log('CHILD ADDED');

			const value = { ...snapshot.val() };

			const data = {
				...value,
				key: snapshot.key,
				indexToIterate: 0,
				deltaLat: null,
				deltaLng: null,
				indexAnimation: 0,
				isOffline: false,
			};

			if (value.location) {
				data['last_location'] = { lat: value.location.latitude, lon: value.location.longitude };
			}

			conductors.push(data);

			const filterConductors = conductors.filter(
				(item) => {
					return !isSuperAdmin ? getClient().technicians.indexOf(item.installation_technician) !== -1 : item;
				},
				[conductors]
			);
			console.log(data);
			// console.log(filterConductors);

			const updateLiveData = [];
			JSON.parse(JSON.stringify([...filterConductors])).forEach((item) => {
				this.state.data.forEach((el) => {
					console.log(item);
					if (item.order === el.keyDB)
						updateLiveData.push({
							...item,
							status_task: el.status_task,
						});
				});
			});
			// console.log(this.state.data);
			// data
			console.log(updateLiveData);

			if (updateLiveData.length > 0) {
				const item = updateLiveData[0];

				item.location !== undefined &&
					this.setState({ center: { lat: item.location.latitude, lng: item.location.longitude } });
			}

			this.setState({ conductors: updateLiveData, currentsAllDrivers: updateLiveData });
		});

		const childRemove = firebaseDatabase.ref('online');

		childRemove.on('child_removed', (snapshot) => {
			console.log('CHILD REMOVE');
			const { conductors } = this.state;

			const keyToFind = snapshot.key;
			const newArray = conductors.filter((conductor, y) => conductor.id != keyToFind);

			this.setState({ conductors: newArray });
		});

		const childChanged = firebaseDatabase.ref('online');
		childChanged.on('child_changed', (snapshot) => {
			let indexToFind = -1;

			let keyToFind = snapshot.key;

			const { conductors } = this.state;

			conductors.filter((conductor, y) => {
				if (conductor.key == keyToFind) {
					indexToFind = y;
				}
			});

			if (indexToFind >= 0) {
				const value = snapshot.val();

				const destinationLocation = [value.location.latitude, value.location.longitude];
				this.moveToNextLocation(destinationLocation, indexToFind);

				console.log('CONDUCTOR HAS MOVED');

				if (snapshot.val().transaction) {
					let conductor = conductors[indexToFind];
					conductor['transaction'] = snapshot.val().transaction;
					this.setState({ conductors });
				}
			}
		});

		this.setState({ childAdded, childChanged, childRemove });
	}

	componentDidMount() {
		const isSuperAdmin = getClient()?.isSuperRoot;

		getAllData('users', (users) => {
			const allTechnician = users.filter(
				(item) => {
					return !isSuperAdmin ? (getClient().technicians || []).indexOf(item._id) !== -1 : item;
				},
				[users]
			);
			this.setState({ allTechnician });
		});

		getClient() &&
			getAllData('service', (services) => {
				const data = services.filter(
					(item) => {
						return !isSuperAdmin
							? getClient().technicians.indexOf(item.installation_technician) !== -1
							: item;
					},
					[services]
				);
				this.setState({ data }, () => {
					this.getOnlineConductors();
				});
			});

		window.addEventListener('keydown', this.handleListenerKeyboard);
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleListenerKeyboard);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.conductors !== this.state.conductors) {
			if (this.state.allTechnician) {
				const formatConductor = this.state.conductors.reduce((acc, el) => ({ ...acc, [el.id]: el }), {});
				const offline = this.state.allTechnician.filter((item) => {
					return formatConductor[item._id] === undefined;
				});

				console.log(this.state.allTechnician);
				console.log(offline);
				this.setState({ offline });
			}
		}
	}

	moveToNextLocation = (location, index) => {
		this.transition(location, index, () => {
			let arrayOfDrivers = this.state.conductors;
			arrayOfDrivers[index] = {
				...arrayOfDrivers[index],
				lat: location[0],
				lng: location[1],
				last_location: { lat: location[0], lon: location[1] },
				indexToIterate: arrayOfDrivers[index].indexToIterate + 1,
			};

			this.setState({ conductors: arrayOfDrivers });
		});
	};

	transition(result, index, cb) {
		if (this.state.conductors[index].last_location) {
			let position = [
				this.state.conductors[index].last_location.lat,
				this.state.conductors[index].last_location.lon,
			];

			this.state.conductors[index].indexAnimation = 0;
			this.state.conductors[index].deltaLat = (result[0] - position[0]) / numDeltas;
			this.state.conductors[index].deltaLng = (result[1] - position[1]) / numDeltas;
			this.moveMarker(cb, index);
		}
	}

	moveMarker = (cb, index) => {
		if (this.state.conductors[index]) {
			if (this.state.conductors[index].last_location) {
				let position = [
					this.state.conductors[index].last_location.lat,
					this.state.conductors[index].last_location.lon,
				];
				position[0] += this.state.conductors[index].deltaLat;
				position[1] += this.state.conductors[index].deltaLng;
				let arrayOfDrivers = this.state.conductors;
				arrayOfDrivers[index] = {
					...arrayOfDrivers[index],
					last_location: { lat: position[0], lon: position[1] },
				};

				this.setState({
					conductors: arrayOfDrivers,
				});
				if (this.state.conductors[index].indexAnimation !== numDeltas) {
					this.state.conductors[index].indexAnimation++;
					setTimeout(() => {
						this.moveMarker(cb, index);
					}, delay);
				} else if (this.state.conductors[index].indexAnimation === numDeltas) {
					cb();
				}
			}
		}
	};

	handleOnChange = (e) => {
		const currentsAllDrivers = [];
		this.state['conductors'].forEach((item) => {
			if (
				(item.installation_technician_meta_data.name || '---')
					.toUpperCase()
					.indexOf(e.target.value.toUpperCase()) === -1
			) {
				return;
			}

			currentsAllDrivers.push(item);
		});

		// console.log(currentsAllDrivers);

		this.setState({ currentsAllDrivers });
	};

	handleListenerKeyboard = (e) => {
		if (e.key === 'Escape') {
			this.setState({ isShowMoreInfoDriver: false });
		}
	};

	handleOnChangeRadio = (e, type) => {
		// console.log(`radio checked:${e.target.value}`);
		// console.log(type);

		switch (type) {
			case 'status':
				switch (e.target.value) {
					case 'active':
						this.setState({ currentsAllDrivers: this.state.conductors, typeSelect: type });
						break;

					default:
						this.setState({ currentsAllDrivers: this.state.offline, typeSelect: type });
						break;
				}
				break;
			default:
				console.log('Filter with status current');
				break;
		}

		// currentsAllDrivers
	};

	handleOnSelectDriver = (id) => {
		// console.log(id);

		const isDriverActive = this.state.typeSelect === 'active';

		const currentDriver = this.state[isDriverActive ? 'conductors' : 'offline'].filter((item) => {
			return item[isDriverActive ? 'id' : '_id'] === id;
		});
		// console.log(currentDriver);
		this.setState({ isVisibleDrawer: true, currentDriver });
	};

	handleOnCloseDrawer = () => {
		this.setState({ isVisibleDrawer: false, currentDriver: [] });
	};

	handleShowMoreDetailsCurrentService = (service) => {
		this.props.history.push(`/admin/servicio/${service.order}`);
	};

	detailsDriver() {
		const style = {
			wrapperProfile: { justifyContent: 'center', display: 'flex' },
			wrapperMoreInformationUser: {
				padding: '16px 0',
				background:
					'linear-gradient(90deg, rgba(255, 111, 0, 1) 0%, rgba(255, 160, 0, 1) 50%, rgba(255, 143, 0, 1) 100%)',
				borderColor: 'rgba(255, 143, 0, 1)',
			},
			paddingAroundLevelOne: { padding: '2em 1em' },
			colorWhite: { color: 'white' },
			titleStyle: { color: 'white', margin: 0 },
		};

		let statusToShow = null;
		let colorToShow = null;
		let storeName = null;

		const currentDriver = this.state.currentDriver.length > 0 ? { ...this.state.currentDriver[0] } : [];

		return !Array.isArray(currentDriver) ? (
			<Drawer
				title="Detalles de usuario"
				placement="right"
				width={320}
				className="wrapper-drawer--driver"
				closable={false}
				onClose={this.handleOnCloseDrawer}
				visible={this.state.isVisibleDrawer}
			>
				<Row>
					<Col xs={12} style={style.wrapperProfile}>
						<Avatar
							className="wrapper-profile"
							src={
								currentDriver.profilePicture !== undefined
									? currentDriver.profilePicture
									: currentDriver.profile_url
							}
							shape="square"
							size={200}
						/>
					</Col>
				</Row>
				<Row center="xs" style={style.wrapperMoreInformationUser}>
					<Col xs={12}>
						<Title level={4} style={style.titleStyle}>
							{`${
								currentDriver.firstName !== undefined
									? currentDriver.firstName
									: currentDriver.first_name
							} ${
								currentDriver.lastName !== undefined ? currentDriver.lastName : currentDriver.last_name
							}`}
						</Title>
					</Col>
					<Col xs={12}>
						<Text style={style.colorWhite}>
							<Badge status={currentDriver.isOffline ? 'error' : 'success'} />
							{currentDriver.isOffline ? 'Desconectado' : 'Conectado'}
						</Text>
					</Col>
					<Col xs={12}>
						<Text style={style.colorWhite}>{currentDriver.phoneNumber}</Text>
					</Col>
				</Row>
				<Row style={style.paddingAroundLevelOne}>
					<Col xs={12}>
						<Row center="xs">
							<Col xs={12}>
								<Title level={4}>Resumen general</Title>
							</Col>
						</Row>
					</Col>
					<ItemDetailDriver
						xs={12}
						md={6}
						type={'thunderbolt'}
						title={`${0} Hr`}
						description={'Tiempo activo'}
					/>
					<ItemDetailDriver
						xs={12}
						md={6}
						type={'dollar'}
						title={formatter.format(0)}
						description={'Ganancias'}
					/>
					<ItemDetailDriver
						xs={12}
						md={6}
						type={'history'}
						title={formatNumber.format(0)}
						description={'Todos los servicios'}
					/>
					<ItemDetailDriver
						xs={12}
						md={6}
						type={'check-circle'}
						title={formatNumber.format(0)}
						description={'Servicios completados'}
					/>
				</Row>
			</Drawer>
		) : null;
	}

	handleOnRemoveService = (item) => {
		console.log(item);
		Modal.confirm({
			title: '¿Desea quitar este servicio?',
			content: 'Esta acción no se puede deshacer',
			okText: 'Si, eliminar',
			okType: 'danger',
			cancelText: 'No',
			onOk: () =>
				removeServiceCurrentConductor(item, () => {
					console.log('Servicio eliminado');
					setTimeout(() => window.location.reload(), 200);
				}),
			onCancel: () => {},
		});
	};

	render() {
		console.log(this.state);
		return (
			<Grid fluid>
				<Row>
					<Col xs={12}>
						<div className="container-map">
							<div className="map">
								<Map
									googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_GOOGLE_KEY}&libraries=places`}
									loadingElement={<div style={{ height: `auto`, width: 'auto' }} />}
									containerElement={<div style={{ height: `100vh`, width: '100%' }} />}
									mapElement={<div style={{ height: `100vh`, width: '100%' }} />}
									doneShowingMap={() => this.setState({ isFirstLoad: false })}
									defaultZoom={32}
									center={this.state.center}
									conductors={this.state.conductors}
									isFirstLoad={this.state.isFirstLoad}
									onMapReady={(ref) => ref && this.state.map == null && this.setState({ map: ref })}
									isMarkerShown={true}
									state={this.state}
									onMapClick={(conductor) => {
										this.setState({ isShowMoreInfoDriver: true, currentDriver: conductor });
									}}
									onHiddenWindowInfoDriver={() => this.setState({ isShowMoreInfoDriver: false })}
									isShowMoreInfoDriver={this.state.isShowMoreInfoDriver}
								/>
							</div>
							<Row>
								<Col xs={12}>
									<Row className="wrapper-container--users">
										<Col xs={12}>
											<FilterListConductor
												state={this.state}
												onChangeState={() => {}}
												onRadio={() => {}}
												onInputFilter={this.handleOnChange}
											/>
											<ListConductorsForState
												state={this.state}
												onShowDetails={this.handleShowMoreDetailsCurrentService}
												onRemoveService={this.handleOnRemoveService}
												onPointerCurrentConductor={() => {}}
											/>
											{/* {this.detailsDriver()} */}
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</Grid>
		);
	}
}

const ItemDetailDriver = (props) => {
	const style = {
		colorPrimary: { color: 'rgb(255, 111, 0)', margin: 0 },
		sizeMedium: { fontSize: 22 },
		paddingWrapper: { padding: '1em 8px' },
	};

	return (
		<Col xs={props.xs ? props.xs : 12} md={props.md ? props.md : 6} style={style.paddingWrapper}>
			<Row middle="xs">
				<Col xs={3}>
					<Icon type={props.type} style={style.sizeMedium} />
				</Col>
				<Col xs={9}>
					<Row>
						<Col xs={12}>
							<Title level={3} style={style.colorPrimary}>
								{props.title}
							</Title>
						</Col>
						<Col xs={12}>
							<Text>{props.description}</Text>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
