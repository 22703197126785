import React from 'react';
import { List, Avatar, Icon, Tag, Menu, Dropdown, Tabs } from 'antd';
import {
	STATUS_PENDING_WORKER,
	WORKER_ACCEPTED,
	WORKER_ARRIVED,
	WORKER_IN_PROGRESS,
	WORKER_REJECTED,
	WORKER_DONE,
} from '../constants/Constans';

import { Row, Col } from 'react-flexbox-grid';

export const ListConductorsForState = (props) => {
	const currentsAllDrivers = props.state.currentsAllDrivers
		.filter((item) => !item.isOffline)
		.map((item) => item.installation_technician);

	return (
		<Row style={{ padding: '1em 0 0', height: '100%', overflow: 'scroll' }}>
			<Col xs={12} style={{ height: 100 }}>
				<Tabs defaultActiveKey="1" onChange={(e) => console.log(e)}>
					<Tabs.TabPane tab="Con servicio" key="1">
						<List
							style={{ padding: '0 0 5em 0' }}
							itemLayout="horizontal"
							dataSource={props.state.currentsAllDrivers.filter((item) => !item.isOffline) || []}
							renderItem={(item, key) => (
								<SingleTechnician
									key={key}
									item={item}
									isActive={true}
									onPointerCurrentConductor={props.onPointerCurrentConductor}
									onRemoveService={props.onRemoveService}
									onShowDetails={props.onShowDetails}
								/>
							)}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab="Sin servicio" key="2">
						<List
							style={{ padding: '0 0 5em 0' }}
							itemLayout="horizontal"
							dataSource={
								props.state.allTechnician.filter(
									(item) => currentsAllDrivers.indexOf(item._id) === -1,
									[props.state.allTechnician]
								) || []
							}
							renderItem={(item, key) => (
								<SingleTechnician
									key={key}
									item={item}
									onPointerCurrentConductor={props.onPointerCurrentConductor}
									onRemoveService={props.onRemoveService}
									onShowDetails={props.onShowDetails}
								/>
							)}
						/>
					</Tabs.TabPane>
				</Tabs>
			</Col>
		</Row>
	);
};

const SingleTechnician = ({ item, onPointerCurrentConductor, onRemoveService, onShowDetails, isActive }) => {
	const profile = item.profilePicture
		? item.profilePicture
		: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAM1BMVEXk5ueutLeqsbTn6eqpr7PJzc/j5ebf4eLZ3N2wtrnBxsjN0NLGysy6v8HT1tissra8wMNxTKO9AAAFDklEQVR4nO2d3XqDIAxAlfivoO//tEOZWzvbVTEpic252W3PF0gAIcsyRVEURVEURVEURVEURVEURVEURVEURVEURVEURflgAFL/AirAqzXO9R7XNBVcy9TbuMHmxjN6lr92cNVVLKEurVfK/zCORVvW8iUBnC02dj+Wpu0z0Y6QlaN5phcwZqjkOkK5HZyPAjkIjSO4fIdfcOwFKkJlX4zPu7Ha1tIcwR3wWxyFhRG6g4Je0YpSPDJCV8a2Sv2zd1O1x/2WMDZCwljH+clRrHfWCLGK8REMiql//2si5+DKWKcWeAGcFMzzNrXC/0TUwQ2s6+LhlcwjTMlYsUIQzPOCb7YBiyHopyLXIEKPEkI/TgeuiidK/R9FniUDOjRDpvm0RhqjMyyXNjDhCfIMYl1gGjIMIuYsnGEYRMRZOMMunaLVwpWRW008v6fYKDIzxCwVAeNSO90BJW6emelYBRF/kHpYGVaoxTDAaxOFsfP9y8hpJ4xd7gOcij7JNGQ1EYFgkPJa1jQEiYZXRaRINKxSDUW9n+FT82lSKadkiru9/4XPqSLWOekGPoY05TAvLm9orm+YWuwHoBHkZKijNBJGmeb61eL6Ff/6q7bLr7yvv3vKGhpDRjvgjGaPz+gUg6YgcvpyAR2FIZ9U6nEEyZRTovmEU32KichpGn7C17XrfyH9gK/c0CMP05HZIM2uf9sEveizKveBy9/6Qt7o89ne33D525cfcIMW6ab+TMEukQbQbu+xu7X3A9bChmWaCeAkG17bpntwXgWxHaMzGPmUaR5dQZiKqRVeUZ3047fi3nAu28h4CHxCsZAgmEH8Y27jJAhm8c+5RQzRQNVGhVFSfxOYIjp/pP7RxzjevYXVGf4eLt+BJ1vCuLuLkrgABgCGXZ2wik5uty+oBvNirI6mkzhAf4Gsb58Hcm67Jzd+KwD10BYPLL3e0MjvKrgAULnOfveF/O4N2Xb9BZom3gJes3F9X5Zze8/6Yt09b4CrqsEjUv8oFBaR2rl+6CZr2xVrp24o/WitBKuGrrpl1+bFkmK2qXTON4VpbdfLa7o7y/WdLxG7lm2Lqh2clOwTegbvc/vj2U78CwhA87Bn8G5Nk3eOb0Nsr9flz3sG78UUtue4kpv1xvjg3TMay62BMlTlP+vrOMnJsRmt/ze0jsfkPPYdAH57hK+34PeOyc8XIXu5xT2HsUkdZz+adwg8HGFfQ3K5jtDvbUiO4Di9/ywHGrL88pDizZ++oTp+an+SMX/ndymUCwmHMdO7yuOx83pUx/eEMU0AvxWndwgidAqOZ8ypCwdEfvvEo6D9HwpA8wzvmOJEqAg9ySu8g4x0Hb9hSB/BANEKJ+LbPBU0lzbAJs4xt1AoshKkUGQmiH8/jJ0gdhTTLmSegHlPE0oOdXALnqDjKYh3px//fSgSWG8UqfrrIICzYYSJXRr9BSPbpNzw7gBjKjKOYI7ReIGqQRIap5+5MdjyvuDkExvGeXSlONWZAP3/AZBwJohU7QJRGU+cTVH18ELmRPNBmibW6MT/k1b0XhdkRBvyT6SB6EYv/GvhSmRNpGngRULsAlxMCGNXp7w3FfdEbTEEDdLI9TdIKRUzUesa3I461ER8cpNT7gMRhpKmYVS9ELOgCUQsa4SsulciKiLbY+AnHD8cpuhISsnxpamI84sbDq9qYJgf8wiiOBrC7Ml7M7ZECCqKoiiKoiiKoiiKoijv5AvJxlZRyNWWLwAAAABJRU5ErkJggg==';
	const fullName = item.name || item.installation_technician_meta_data.name || '---';
	return (
		<List.Item>
			<List.Item.Meta
				avatar={<Avatar src={profile} />}
				title={
					<a
						href={null}
						style={{ margin: 0, cursor: 'pointer' }}
						onClick={() => onPointerCurrentConductor(item)}
					>
						{fullName}
					</a>
				}
				description={<CurrentStatus {...item} isActive={isActive} />}
			/>
			{isActive && (
				<div>
					<Dropdown
						overlay={
							<Menu>
								<Menu.Item key="0">
									<a href={null} onClick={() => onRemoveService(item)}>
										Quitar servicio
									</a>
								</Menu.Item>
								<Menu.Item key="1">
									<a href={null} onClick={() => onShowDetails(item)}>
										Ver detalles
									</a>
								</Menu.Item>
							</Menu>
						}
						trigger={['click']}
					>
						<Icon type="more" style={{ color: '#5f5f5f', fontSize: 16 }} />
					</Dropdown>
				</div>
			)}
		</List.Item>
	);
};

const getCurrentStatus = (status_task) => {
	let colorToShow, statusToShow;

	switch (status_task) {
		case WORKER_ACCEPTED: {
			statusToShow = 'Conductor Acepto Servicio';
			colorToShow = '#e5ad00';
			break;
		}

		case WORKER_ARRIVED: {
			statusToShow = 'Llego el trabajador';
			colorToShow = '#D50000';
			break;
		}

		case WORKER_REJECTED: {
			statusToShow = 'Trabajo rechazado';
			colorToShow = '#D50000';
			break;
		}

		case STATUS_PENDING_WORKER: {
			statusToShow = 'Esperando respuesta';
			colorToShow = '#cc1244';
			break;
		}

		case WORKER_IN_PROGRESS: {
			statusToShow = 'Trabajo en progreso';
			colorToShow = '#EF6C00';
			break;
		}

		case WORKER_DONE: {
			statusToShow = 'Trabajo Terminado';
			colorToShow = '#2E7D32';
			break;
		}
	}

	return { colorToShow, statusToShow };
};

const CurrentStatus = ({ branch_office, status_task, isActive }) => {
	const { colorToShow, statusToShow } = getCurrentStatus(status_task);
	console.log(isActive);
	console.log(statusToShow);
	if (!statusToShow) return 'En linea';
	if (!isActive) return 'Desconectado';

	const style = {
		title: {
			margin: 0,
			fontSize: 12,
			color: '#3b80ff',
			fontWeight: 600,
		},
	};

	return (
		<div>
			<Row middle="xs">
				<Col xs={2}>
					<Icon type="shop" theme="twoTone" />
				</Col>
				<Col xs={10} style={{ padding: '0 8px 0 0px' }}>
					<p style={style.title}>{branch_office || '---'}</p>
				</Col>
			</Row>
			<Tag color={colorToShow}> {statusToShow}</Tag>
		</div>
	);
};
